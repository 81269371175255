const neutral = {
  90: '#202328',
  80: '#32373E',
  70: '#424952',
  60: '#5F6771',
  50: '#6F7780',
  40: '#8E959E',
  30: '#A1A8B0',
  20: '#CED3DA',
  10: '#E7EBEE',
  5: '#F4F5F7',
} as const;

const primary = {
  90: '#002152',
  80: '#002F75',
  70: '#0045AD',
  60: '#0254CF',
  50: '#146EF5',
  40: '#3F8BFD',
  30: '#6BA6FF',
  20: '#9EC5FF',
  10: '#DCEBFE',
  5: '#EBF3FF',
} as const;

const seaweed = {
  90: '#002533',
  80: '#003947',
  70: '#004F52',
  60: '#006B62',
  50: '#018479',
  40: '#07A198',
  30: '#22CBC1',
  20: '#89E1DB',
  10: '#C2F4F1',
  5: '#E0FAF8',
} as const;

const eggplant = {
  90: '#3F0250',
  80: '#57096D',
  70: '#7D2099',
  60: '#9D30BC',
  50: '#B245D1',
  40: '#C467DE',
  30: '#D88EEC',
  20: '#E5AFF3',
  10: '#F8E3FD',
  5: '#FCF1FE',
} as const;

const critical = {
  90: '#4D0505',
  80: '#6E0707',
  70: '#940A0A',
  60: '#BD0F0F',
  50: '#DD3131',
  40: '#FF584C',
  30: '#FF837A',
  20: '#FFB8B3',
  10: '#FFE3E0',
  5: '#FFF1F0',
} as const;

const success = {
  90: '#022C16',
  80: '#033A1E',
  70: '#044E28',
  60: '#0C6734',
  50: '#08873F',
  40: '#0BA84F',
  30: '#13C35F',
  20: '#95E4AA',
  10: '#C5F6DA',
  5: '#E5FBEE',
} as const;

const warning = {
  80: '#7A4900',
  60: '#C0873A',
  50: '#FFC700',
  10: '#FFE895',
  5: '#FFF1D6',
} as const;

export const status = {
  critical: critical[50],
  criticalHover: critical[40],
  criticalPressed: critical[60],
  disabled: neutral[20],
  success: success[50],
  successHover: success[40],
  successPressed: success[60],
  warning: warning[50],
  warningHover: warning[5],
  warningPressed: warning[60],
} as const;

export const colors = {
  text: {
    critical: status.critical,
    default: neutral[90],
    disabled: status.disabled,
    interactive: primary[50],
    subdued: neutral[50],
    white: '#ffffff',
    highlight: '#6E9CD4',
  },
  icon: {
    critical: status.critical,
    default: neutral[60],
    disabled: status.disabled,
    hovered: neutral[90],
    select: primary[50],
    subdued: neutral[40],
    success: status.success,
    warning: status.warning,
    white: '#ffffff',
  },
  critical,
  critical90: critical[90],
  critical80: critical[80],
  critical70: critical[70],
  critical60: critical[60],
  critical50: critical[50],
  critical40: critical[40],
  critical30: critical[30],
  critical20: critical[20],
  critical10: critical[10],
  critical5: critical[5],
  neutral,
  neutral90: neutral[90],
  neutral80: neutral[80],
  neutral70: neutral[70],
  neutral60: neutral[60],
  neutral50: neutral[50],
  neutral40: neutral[40],
  neutral30: neutral[30],
  neutral20: neutral[20],
  neutral10: neutral[10],
  neutral5: neutral[5],
  primary,
  primary90: primary[90],
  primary80: primary[80],
  primary70: primary[70],
  primary60: primary[60],
  primary50: primary[50],
  primary40: primary[40],
  primary30: primary[30],
  primary20: primary[20],
  primary10: primary[10],
  primary5: primary[5],
  success,
  success90: success[90],
  success80: success[80],
  success70: success[70],
  success60: success[60],
  success50: success[50],
  success40: success[40],
  success30: success[30],
  success20: success[20],
  success10: success[10],
  success5: success[5],
  warning,
  warning80: warning[80],
  warning60: warning[60],
  warning50: warning[50],
  warning10: warning[10],
  warning5: warning[5],
  secondary: {
    seaweed,
    seaweed90: seaweed[90],
    seaweed80: seaweed[80],
    seaweed70: seaweed[70],
    seaweed60: seaweed[60],
    seaweed50: seaweed[50],
    seaweed40: seaweed[40],
    seaweed30: seaweed[30],
    seaweed20: seaweed[20],
    seaweed10: seaweed[10],
    seaweed5: seaweed[5],
    eggplant,
    eggplant90: eggplant[90],
    eggplant80: eggplant[80],
    eggplant70: eggplant[70],
    eggplant60: eggplant[60],
    eggplant50: eggplant[50],
    eggplant40: eggplant[40],
    eggplant30: eggplant[30],
    eggplant20: eggplant[20],
    eggplant10: eggplant[10],
    eggplant5: eggplant[5],
  },
  status,
  white: '#ffffff',
  black: '#000000',
} as const;
