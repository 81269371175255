import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useSlideCarousel } from './provider';
import { IconButton } from '../icon-button';
import { NakedButton } from '../../naked';

export const SlideNavigation = () => {
  const { activeId, setActiveId, slideIds } = useSlideCarousel();
  const activeIndex = slideIds.indexOf(activeId);

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveId(slideIds[activeIndex - 1]);
    }
  };

  const handleNext = () => {
    if (activeIndex < slideIds.length - 1) {
      setActiveId(slideIds[activeIndex + 1]);
    }
  };

  return (
    <footer css={buttonContainerStyle}>
      <div css={css({ display: 'flex', gap: theme.spacing(1) })}>
        <IconButton css={buttonStyle} size='xsmall' label='Next' disabled={activeIndex === 0} onClick={handlePrev}>
          <Icon name='caret-left' />
        </IconButton>
        <IconButton
          css={buttonStyle}
          size='xsmall'
          label='Forward'
          disabled={activeIndex === slideIds.length - 1}
          onClick={handleNext}
        >
          <Icon name='caret-right' />
        </IconButton>
      </div>
      <div css={css({ display: 'flex', gap: theme.spacing(1) })}>
        {slideIds.map((id, index) => (
          <ActiveSectionIndicator key={id} isActive={activeIndex === index} onClick={() => setActiveId(id)} />
        ))}
      </div>
    </footer>
  );
};

const buttonStyle = css({
  padding: theme.spacing(0.5),
});

interface ActiveSectionIndicatorProps {
  isActive: boolean;
  onClick: () => void;
}

const ActiveSectionIndicator = ({ isActive, onClick }: ActiveSectionIndicatorProps) => (
  <NakedButton
    onClick={onClick}
    css={css`
      display: block;
      height: 8px;
      width: 8px;
      border-radius: ${theme.borderRadius.full};
      transition: background-color 0.3s ease;
      background-color: ${isActive ? theme.colors.neutral90 : theme.colors.neutral20};
      cursor: pointer;
    `}
  />
);

const buttonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  alignItems: 'baseline',
});
