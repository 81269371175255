import { authnClient, resetPassword } from '@frontend/auth';

export const sendPasswordReset = async (username: string): Promise<void> => {
  return resetPassword(username).then(() => {}); // ignore response, forward any errors
};

export const signInWithPassword = async (username: string, password: string): Promise<string> => {
  authnClient.changeAuthMethod('legacy');
  return authnClient.signIn({ username, password });
};
