import { css } from '@emotion/react';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { ButtonProps } from '../components/buttons/button-type';

type Props = Omit<ButtonProps, 'children'> & {
  children?: ReactNode;
  trackingId?: string;
  className?: string;
};

export const NakedButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, trackingId, type = 'button', ...rest }, ref) => (
    <button
      css={css`
        background: none;
        border: none;
        cursor: pointer;
        font-size: initial;
        padding: 0;
      `}
      data-trackingid={trackingId}
      type={type}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  )
);
