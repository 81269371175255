import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { Text } from '../../text';
import { useStepperContext } from '../provider/stepper.provider';
import { useStepperCardContext } from '../provider/stepper-card.provider';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { TextLink } from '../../text-link/text-link.component';
import { PrimaryButton } from '../../buttons/primary/primary-button';
import { SecondaryButton } from '../../buttons/secondary/secondary-button';

export type StepperButtonBarProps = {
  helperText?: string;
  children: ReactNode | ReactNode[];
};

export const StepperButtonBar = ({ helperText, children, ...rest }: Partial<StepperButtonBarProps>) => {
  const smallMin = useMatchMedia({ minWidth: breakpoints.small.min });
  const { stepStatus } = useStepperContext();
  const { step } = useStepperCardContext();

  return (
    <>
      {(stepStatus[step] === 'active' || stepStatus[step] === 'currActive' || stepStatus[step] === 'errorActive') && (
        <div className='step-button-bar' {...rest}>
          {smallMin && !!helperText && (
            <Text
              as={'span'}
              css={css`
                margin: auto ${theme.spacing(1)};
                max-width: 300px;
              `}
            >
              {helperText}
            </Text>
          )}
          {children}
        </div>
      )}
    </>
  );
};

type ButtonDefinition = {
  type: 'next' | 'previous' | 'skip' | 'none';
  position: 'primary' | 'secondary' | 'tertiary';
  onClick?: () => void;
  trackingId?: string;
  children: ReactNode;
  disabled?: boolean;
  skipTo?: number;
};

const generateButtonProps = ({
  button,
  step,
  nextFunction,
  skipFunction,
  previousFunction,
}: {
  button: ButtonDefinition;
  step: number;
  nextFunction?: (step: number) => void;
  skipFunction?: (skipTo: number, step: number) => void;
  previousFunction?: (step: number) => void;
}) => {
  switch (button.type) {
    case 'next': {
      const onClick = () => {
        if (button.onClick) {
          button.onClick();
        }
        nextFunction?.(step);
      };
      return {
        ...button,
        onClick,
      };
    }
    case 'previous': {
      const onClick = () => {
        if (button.onClick) {
          button.onClick();
        }
        previousFunction?.(step);
      };
      return {
        ...button,
        onClick,
      };
    }
    case 'skip': {
      const onClick = () => {
        if (button.onClick) {
          button.onClick();
        }
        if (button.skipTo) {
          skipFunction?.(button.skipTo, step);
        } else {
          nextFunction?.(step);
        }
      };
      return {
        ...button,
        onClick,
      };
    }
    default:
      return {
        ...button,
      };
  }
};

export const StepperButtonBarAlternate = ({
  helperText,
  buttons = [],
  className,
  children,
}: {
  helperText?: string;
  buttons?: ButtonDefinition[];
  className?: string;
  children?: ReactNode;
}) => {
  const smallMin = useMatchMedia({ minWidth: breakpoints.small.min });
  const { stepStatus } = useStepperContext();
  const { step } = useStepperCardContext();

  const resolvedClassName = className ? `${className} step-button-bar` : 'step-button-bar';

  if (!(stepStatus[step] === 'active' || stepStatus[step] === 'currActive' || stepStatus[step] === 'errorActive')) {
    return null;
  }

  return (
    <div className={resolvedClassName} style={{ flexDirection: 'column' }}>
      <div
        css={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto auto',
          gridTemplateAreas: '"tertiary helper secondary primary"',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        {smallMin && !!helperText && (
          <Text
            as={'span'}
            css={css`
              grid-area: helper;
              justify-self: end;
            `}
            color='subdued'
          >
            {helperText}
          </Text>
        )}
        {children ?? buttons.map((button) => <StepperButtonAlternate key={button.position} {...button} />)}
      </div>
    </div>
  );
};

export const StepperButtonAlternate = (button: ButtonDefinition) => {
  const { nextFunction, skipFunction, previousFunction } = useStepperContext();
  const { step } = useStepperCardContext();
  const { position, disabled, onClick, trackingId, children } = generateButtonProps({
    button,
    step,
    nextFunction,
    skipFunction,
    previousFunction,
  });

  let component;
  if (position === 'tertiary') {
    component = (
      <TextLink
        size='large'
        disabled={disabled}
        weight='bold'
        className='step-button'
        css={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: theme.spacing(1), gridArea: position }}
        onClick={onClick}
        trackingId={trackingId}
      >
        {children}
      </TextLink>
    );
  } else {
    const Button = position === 'primary' ? PrimaryButton : SecondaryButton;
    component = (
      <Button
        disabled={disabled}
        size='small'
        onClick={onClick}
        className='step-button'
        css={{ display: 'flex', gap: theme.spacing(1), width: 'auto', gridArea: position, svg: { margin: 0 } }}
        trackingId={trackingId}
      >
        {children}
      </Button>
    );
  }
  return component;
};
