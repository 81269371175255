import { getWeaveToken, isWeaveTokenActive } from '@frontend/auth-helpers';
import {
  EmailField,
  FormRow,
  Heading,
  PasswordField,
  PrimaryButton,
  Text,
  TextLink,
  useForm,
  useFormField,
  PasswordProps,
} from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { useEffect, useMemo, useRef, useState, type PropsWithChildren } from 'react';
import { useTranslation } from '@frontend/i18n';
import { workforceSignin, workforceSigninCallback, PasswordResetOIDC, SignInError, ContentLoader } from '../components';
import { hydraShellService, signInWithPassword } from '../services';
import { authnClient } from '@frontend/auth';
import appConfig from '@frontend/env';
import { sentry } from '@frontend/tracking';
import { MFAForm } from './mfa-form';

const navigate = (url: string) => {
  window.location.href = url;
};

interface Props {
  setIsShowingBGImage: (value: boolean) => void;
}

// const monitorFrameForConsentChallenge = (frame: HTMLIFrameElement) => {
//   return new Promise<string>((resolve, reject) => {
//     const interval = setInterval(() => {
//       try {
//         if (frame.contentWindow?.location.href.includes('consent_challenge')) {
//           const challenge = new URLSearchParams(frame.contentWindow?.location.search).get('consent_challenge');
//           if (challenge) {
//             resolve(challenge);
//             clearInterval(interval);
//           }
//         }
//       } catch (e) {
//         console.log('Error monitoring frame for consent challenge:', e);
//       }
//     }, 50);
//     setTimeout(() => {
//       clearInterval(interval);
//       reject('Consent challenge not found');
//     }, 5000);
//   });
// };

export const LoginFormComponent = ({ setIsShowingBGImage }: Props) => {
  const { t } = useTranslation('SignIn');

  const currentState = useMemo(() => hydraShellService.getCurrentState(), []);
  const frame = useRef<HTMLIFrameElement>(null);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [isCheckingConsent, setIsCheckingConsent] = useState(currentState === 'consent');
  const [isLoggingIn, setIsLoggingIn] = useState(() => window.location.href.includes('/sign-in/callback'));
  const [isChecking3rdPartyCookies, setIsChecking3rdPartyCookies] = useState(false);
  const [has3rdPartyCookiesEnabled, setHas3rdPartyCookiesEnabled] = useState(false);

  const [errorCode, setErrorCode] = useState<number>();
  const [isError, setIsError] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);

  const [mfaEnabled, setMfaEnabled] = useState(false);

  const signInWithPasswordHandler = (username: string, password: string) => {
    setIsCheckingPassword(true);
    setIsError(false);
    //if no login challenge is in url, then rerdirect user back to portal link
    const loginChallenge = hydraShellService.getLoginChallenge();
    if (loginChallenge === '') {
      setTimeout(() => {
        navigate(appConfig.DEFAULT_REDIRECT);
      }, 500);
    }
    // send username and password to backend
    // if successful, redirect to the requesting application
    // if unsuccessful, set error message
    return signInWithPassword(username, password)
      .then((weaveJWT: string) => {
        setIsCheckingPassword(false);
        setIsLoggingIn(true);
        const currentState = hydraShellService.getCurrentState();
        switch (currentState) {
          case 'login':
            hydraShellService.acceptOAuth2Flow(weaveJWT).then((res) => {
              if (!!res.redirectTo) {
                // if (has3rdPartyCookiesEnabled) {
                //   handleConsentWithIframe(weaveJWT, res.redirectTo);
                // } else {
                //   navigate(res.redirectTo);
                // }
                console.info('has3rdPartyCookiesEnabled', has3rdPartyCookiesEnabled);
                navigate(res.redirectTo);
              }
            });
            break;
          default:
            // If we are in this state, then we don't know what client initialized the flow so send them to the default redirect
            setIsLoggingIn(true);
            navigate(appConfig.DEFAULT_REDIRECT);
            break;
        }
      })
      .catch(() => {
        setIsCheckingPassword(false);
        setIsLoggingIn(false);
        setIsError(true);
        // TODO: if error other than 401, send error to sentry
        setLoginAttempts(loginAttempts + 1);
        if (loginAttempts > 2) {
          setErrorCode(402);
        } else {
          setErrorCode(401);
        }
      });
  };

  useEffect(() => {
    setIsCheckingStatus(true);
    const isAuthed = isWeaveTokenActive();
    const weaveJWT = getWeaveToken() || ''; // if already authenticated then this will never be undefined
    if (currentState === 'login') {
      handleInitializeOAuth2Flow(isAuthed, weaveJWT);
    }
    if (currentState === 'consent' && isAuthed) {
      handleConsentQueryParam(weaveJWT);
    }
    if (currentState === 'logout') {
      handleLogoutFlow(weaveJWT);
    }
    if (currentState === 'none') {
      setIsCheckingStatus(false);
    }
    if (window.location.href.includes('/sign-in/callback')) {
      handleWorkforceCallback();
    }
  }, [currentState]);

  useEffect(() => {
    setIsChecking3rdPartyCookies(true);
    const iframe3rdpartycookiecheck = document.createElement('iframe');
    //this website just post-messages whether 3rd party cookies are enabled or not.
    //TODO: We should host our own version of this
    iframe3rdpartycookiecheck.src = 'https://mindmup.github.io/3rdpartycookiecheck/start.html';
    iframe3rdpartycookiecheck.style.display = 'none';
    iframe3rdpartycookiecheck.id = 'iframe3rdpartycookiecheck';
    document.body.appendChild(iframe3rdpartycookiecheck);
    const receiveMessage = (evt: MessageEvent<any>) => {
      setIsChecking3rdPartyCookies(false);
      if (evt.data === 'MM:3PCunsupported') {
        console.info('3rd party cookies are disabled');
        setHas3rdPartyCookiesEnabled(false);
      } else if (evt.data === 'MM:3PCsupported') {
        console.info('3rd party cookies are enabled');
        setHas3rdPartyCookiesEnabled(true);
      }
    };
    window.addEventListener('message', receiveMessage);
    //if we don't get a response in 1 second, we don't wait any longer.
    setTimeout(() => {
      setIsChecking3rdPartyCookies(false);
    }, 1000);
    return () => {
      const node = document.getElementById('iframe3rdpartycookiecheck');
      if (node) {
        document.body.removeChild(node);
      }
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const handleInitializeOAuth2Flow = (isAuthed: boolean, weaveJWT: string) => {
    let redirectURL: string | undefined;
    hydraShellService
      .initializeOAuth2Flow()
      .then((res) => {
        setIsCheckingStatus(false);

        // Store redirectURL for later use in the catch block
        if (res.requestUrl) {
          redirectURL = getRedirectURL(res.requestUrl);
        }

        // if we are already authenticated, we can accept the OAuth2 flow
        if (isAuthed) {
          setIsLoggingIn(true);
          return hydraShellService.acceptOAuth2Flow(weaveJWT).then((res) => {
            navigate(res.redirectTo || redirectURL || appConfig.DEFAULT_REDIRECT);
          });
        }
        return {};
      })
      .catch(() => {
        setIsCheckingStatus(false);
        // alerts.error(t('Error initializing OAuth2 flow'));
        navigate(redirectURL || appConfig.DEFAULT_REDIRECT);
      });
  };

  const handleConsentQueryParam = (weaveJWT: string) => {
    let redirectURL: string | undefined;
    setIsCheckingConsent(true);
    hydraShellService
      .initializeConsentFlow(weaveJWT)
      .then((res) => {
        setIsCheckingStatus(false);
        // setIsCheckingConsent(false);  intentionally not turning off consent state here
        // Store redirectURL for later use in the catch block
        if (res.requestUrl) {
          redirectURL = getRedirectURL(res.requestUrl);
        }
        return hydraShellService.acceptConsentFlow(weaveJWT, res.scope || [], res.client?.clientId);
      })
      .catch(() => {
        setIsCheckingStatus(false);
        // setIsCheckingConsent(false);  intentionally not turning off consent state here
        // Use redirectURL in the catch block
        navigate(redirectURL || appConfig.DEFAULT_REDIRECT);
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleConsentWithIframe = (weaveJWT: string, redirectTo: string) => {
  //   let finalRedirectUrl = redirectTo;
  //   return Promise.resolve()
  //     .then(() => {
  //       // Store redirectURL for later use in the catch block
  //       if (finalRedirectUrl && frame.current) {
  //         frame.current.src = redirectTo;
  //         setIsCheckingConsent(true);
  //         return monitorFrameForConsentChallenge(frame.current)
  //           .then((challenge) => {
  //             hydraShellService
  //               .initializeConsentFlow(weaveJWT, challenge)
  //               .then((res) => {
  //                 setIsCheckingStatus(false);
  //                 // Store redirectURL for later use in the catch block
  //                 if (res.requestUrl) {
  //                   finalRedirectUrl = getRedirectURL(res.requestUrl) || appConfig.DEFAULT_REDIRECT;
  //                 }
  //                 return hydraShellService.acceptConsentFlow(
  //                   weaveJWT,
  //                   res.scope || [],
  //                   res.client?.clientId,
  //                   challenge
  //                 );
  //               })
  //               .catch(() => {
  //                 setIsCheckingStatus(false);
  //                 throw new Error('Error initializing consent flow in hydra shell service');
  //               });
  //           })
  //           .catch(() => {
  //             throw new Error('Error initializing consent from iframe contentwindow');
  //           });
  //       } else {
  //         throw new Error('Error initializing consent. no request url or no frame');
  //       }
  //     })
  //     .catch((err: Error) => {
  //       sentry.error({
  //         error: err && 'message' in err ? err.message : 'Error initializing consent flow',
  //         topic: 'consent',
  //       });
  //       navigate(finalRedirectUrl || appConfig.DEFAULT_REDIRECT);
  //     });
  // };

  const getRedirectURL = (requestUrl: string): string | undefined => {
    try {
      // Parse the requestUrl using the URL class
      const url = new URL(requestUrl);

      // Extract the value of the 'redirect_uri' query parameter
      const redirectUriParam = url.searchParams.get('redirect_uri');

      // Return the decoded value of the parameter
      return redirectUriParam ? getHostFromURL(decodeURIComponent(redirectUriParam)) : undefined;
    } catch (error) {
      console.error('Error parsing requestUrl:', error);
      sentry.error({
        error: 'Error parsing requestUrl',
        topic: 'auth',
      });
      return;
    }
  };

  const getHostFromURL = (inUrl: string): string | undefined => {
    try {
      // Parse the requestUrl using the URL class
      const url = new URL(inUrl);

      // Extract and return the protocol and host property
      return `${url.protocol}//${url.host}`;
    } catch (error) {
      console.error('Error parsing requestUrl:', error);
      return;
    }
  };

  const handleLogoutFlow = async (weaveJWT: string) => {
    hydraShellService
      .initializeLogoutFlow(weaveJWT)
      .then(() => {
        return authnClient.signOut();
      })
      .then(() => {
        return hydraShellService.acceptLogoutFlow(weaveJWT);
      })
      .catch(() => {
        authnClient.signOut().finally(() => {
          setIsCheckingStatus(false);
          localStorage.clear();
          navigate(appConfig.DEFAULT_REDIRECT);
        });
      })
      .finally(() => setIsCheckingStatus(false));
  };

  const handleWorkforceCallback = () => {
    workforceSigninCallback()
      .then((clientTokens) => {
        setIsCheckingStatus(false);
        return handleInitializeOAuth2Flow(true, clientTokens.weaveToken || '');
      })
      .catch(() => {
        setIsCheckingStatus(false);
        // everything else failed so redirect to the default redirect
        navigate(appConfig.DEFAULT_REDIRECT);
      });
  };

  if (isLoggingIn || isCheckingConsent) {
    return (
      <>
        <FormWrap>
          <ContentLoader message={t('Logging in...')} show />
        </FormWrap>
        <iframe ref={frame} style={{ display: 'none' }} />
      </>
    );
  }

  if (isResettingPassword) {
    return (
      <PasswordResetOIDC
        onBack={() => {
          setIsShowingBGImage(false);
          setIsError(false);
          setIsResettingPassword(false);
        }}
      />
    );
  }

  return (
    <>
      <iframe ref={frame} style={{ display: 'none' }} />
      {mfaEnabled ? (
        <FormWrap>
          <MFAForm setMfaEnabled={setMfaEnabled} />
        </FormWrap>
      ) : (
        <>
          <FormWrap>
            <Heading>{t('Welcome back')}</Heading>
            <Text>{t('Log in to your Weave account')}</Text>
            <LoginFormInner
              isCheckingPassword={isCheckingPassword}
              isCheckingStatus={isCheckingStatus || isChecking3rdPartyCookies}
              onSubmitPassword={signInWithPasswordHandler}
              onSubmitSSO={workforceSignin}
              setMfaEnabled={setMfaEnabled}
              onClickForgotPassword={() => {
                setIsShowingBGImage(true);
                setIsResettingPassword(true);
              }}
            />
          </FormWrap>
          <div
            css={css`
              position: absolute;
              bottom: 15%;
              left: 10%;
              width: 80%;
              @media screen and (max-width: 1700px) {
                bottom: 10%;
              }
              @media screen and (max-width: 1300px) {
                bottom: 8%;
              }
            `}
          >
            {isError ? <SignInError errorCode={errorCode} /> : null}
          </div>
        </>
      )}
    </>
  );
};

type FormProps = {
  setMfaEnabled: (value: boolean) => void;
  isCheckingPassword: boolean;
  isCheckingStatus: boolean;
  onClickForgotPassword: () => void;
  onSubmitSSO: (username: string) => void;
  onSubmitPassword: (username: string, password: string) => void;
};
const LoginFormInner = ({
  setMfaEnabled,
  isCheckingPassword,
  isCheckingStatus,
  onSubmitPassword,
  onSubmitSSO,
  onClickForgotPassword,
}: FormProps) => {
  const { t } = useTranslation('SignIn');
  // username props
  const usernameProps = useFormField({ type: 'email', required: true });
  const passwordProps = useFormField({ type: 'password', required: true, minChars: 1 });
  const usernameRef = useRef<HTMLDivElement>(null);
  const isSSO = usernameProps.value.endsWith('@getweave.com');

  //TODO: this is to allow developer to test without showing these changes to user. FF doesn't work well on gatekeeper
  const showMFA = false;

  //auto-focus on the username field
  useEffect(() => {
    if (usernameRef?.current) {
      //setting a timeout here to allow the browser enough time to attempt an autocomplete before we focus
      setTimeout(() => {
        usernameRef?.current?.querySelector('input')?.focus();
      }, 300);
    }
  }, [usernameRef]);

  const { formProps } = useForm({
    fields: {},
    onSubmit: () =>
      isSSO ? onSubmitSSO(usernameProps.value) : onSubmitPassword(usernameProps.value, passwordProps.value),
  });

  const isSubmitButtonEnabled =
    !isCheckingPassword && !isCheckingStatus && (isSSO || (!passwordProps.error && !usernameProps.error));
  return (
    <form {...formProps}>
      <FormRow
        css={css`
          text-align: initial;
          margin: ${theme.spacing(4, 0)};
        `}
      >
        <EmailField ref={usernameRef} name='username' {...usernameProps} label={t('Email')} />
      </FormRow>

      {!isSSO && (
        <FormRow
          css={css`
            text-align: initial;
            margin: ${theme.spacing(0, 0, 4)};
          `}
        >
          <PasswordField
            active={passwordProps.active}
            aria-invalid={passwordProps['aria-invalid']}
            error={passwordProps.error}
            id={passwordProps.id}
            label={t('Password')}
            minChars={(passwordProps as PasswordProps).minChars}
            name='password'
            onBlur={() => passwordProps.onBlur()}
            onChange={passwordProps.onChange}
            onFocus={() => passwordProps.onFocus()}
            required={passwordProps.required}
            touched={passwordProps.touched}
            value={passwordProps.value.toString()}
          />
        </FormRow>
      )}

      <PrimaryButton disabled={!isSubmitButtonEnabled} size='large' type='submit'>
        {isCheckingPassword
          ? t('Verifying...')
          : isCheckingStatus
          ? t('Checking Status...')
          : isSSO
          ? t('Proceed with SSO')
          : t('Log In')}
      </PrimaryButton>

      <TextLink
        css={css`
          display: flex;
          margin: ${theme.spacing(4, 0, 0)};
          cursor: pointer;
          text-align: left;
          width: 100%;
        `}
        onClick={onClickForgotPassword}
      >
        {t('Forgot Password?')}
      </TextLink>
      {showMFA && (
        <TextLink
          css={css`
            display: flex;
            margin: ${theme.spacing(4, 0, 0)};
            cursor: pointer;
            text-align: left;
            width: 100%;
          `}
          onClick={() => {
            setMfaEnabled(true);
          }}
        >
          {t('MFA Verification')}
        </TextLink>
      )}
    </form>
  );
};

const FormWrap = ({ children }: PropsWithChildren) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        margin: 0 25%;
        @media screen and (max-width: 1300px) {
          width: 60%;
        }
        @media screen and (max-width: 1100px) {
          width: 50%;
        }
        @media screen and (max-width: 780px) {
          width: 60%;
          margin: auto;
        }

        @media screen and (max-width: 550px) {
          width: 70%;
          margin: auto;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
};
