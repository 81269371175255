import { forwardRef, ElementType } from 'react';
import { NakedButton } from '../../naked';
import { StyledBtnProps } from './types';
import { useStyles } from '../../use-styles';

export const StyledIconBtn = forwardRef<HTMLButtonElement | HTMLAnchorElement, StyledBtnProps>(
  (
    {
      children,
      color = 'default',
      size,
      to,
      href,
      trackingId,
      showLabelAlways,
      label,
      labelPlacement = 'right',
      destructive,
      showLabelWhenDisabled,
      ...rest
    },
    ref
  ) => {
    const isLink = !!to || !!href;
    const Component: ElementType = !isLink ? NakedButton : 'a';
    const styles = useStyles('IconButton', { color, labelPlacement, size, showLabelAlways, destructive });
    const { disabled, onClick, ...disabledRest } = rest;
    return (
      <Component
        css={styles}
        {...(showLabelWhenDisabled ? disabledRest : rest)}
        onClick={disabled ? undefined : onClick}
        ref={ref as any}
        href={disabled ? to ?? href : undefined}
        data-trackingid={trackingId}
        aria-disabled={disabled}
      >
        {children}
        {showLabelAlways && label}
      </Component>
    );
  }
);
