import { KeyboardEventHandler, MouseEventHandler, useLayoutEffect } from 'react';
import { Placement, autoUpdate, UseFloatingReturn, ExtendedRefs, useFloating } from '@floating-ui/react';
import { useDidUpdate } from '../../../hooks';
import {
  InteractionOptions,
  MiddlewareOptions,
  OverrideEvent,
  conditionallyStopPropagation,
  usePopover,
} from '../use-popover';

export type UsePopoverDialogResponse<
  TriggerElement extends HTMLElement = HTMLButtonElement,
  UseDefaultEvent extends boolean = false,
  DialogElement extends HTMLElement = HTMLDialogElement
> = Pick<UseFloatingReturn<TriggerElement>, 'refs'> & {
  isOpen: boolean;
  close: () => void;
  open: () => void;
} & Omit<PropGetters<TriggerElement, UseDefaultEvent, DialogElement>, 'getItemProps' | 'getMenuProps'>;

type AdditionalDialogProps<T extends HTMLElement> = {
  context: ReturnType<typeof useFloating<T>>['context'];
  nodeId: string;
  isOpen: boolean;
  descriptionId: string;
  initialFocus: number;
  labelId: string;
};

export type PropGetters<
  TriggerElement extends HTMLElement,
  UseDefaultEvent extends boolean = false,
  DialogElement extends HTMLElement = HTMLDialogElement
> = {
  getTriggerProps: <TriggerProps extends OverrideEvent<TriggerElement, UseDefaultEvent>>(
    userProps?: TriggerProps
  ) => TriggerProps & { ref: ExtendedRefs<TriggerElement>['setReference'] };
  getDialogProps: <DialogProps extends OverrideEvent<DialogElement, UseDefaultEvent>>(
    userProps?: DialogProps
  ) => DialogProps & AdditionalDialogProps<DialogElement>;
};

export type UsePopoverDialogProps<TriggerElement extends HTMLElement = HTMLButtonElement> = {
  placement?: Placement;
  initialOffset?: { x: number; y: number };
  initialFocus?: number;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (open: boolean) => void;
  middlewareOptions?: Partial<MiddlewareOptions>;
  transform?: boolean;
  interactionOptions?: Partial<InteractionOptions<TriggerElement>>;
};

export const usePopoverDialog = <
  TriggerElement extends HTMLElement = HTMLButtonElement,
  UseDefaultEvent extends boolean = false,
  DialogElement extends HTMLElement = HTMLDialogElement
>({
  placement = 'right-start',
  initialOffset,
  initialFocus = 0,
  onOpen,
  onClose,
  onToggle,
  middlewareOptions = {},
  interactionOptions = {},
  transform,
}: UsePopoverDialogProps<TriggerElement> = {}): UsePopoverDialogResponse<
  TriggerElement,
  UseDefaultEvent,
  DialogElement
> => {
  const {
    x,
    y,
    strategy,
    nodeId,
    context,
    // activeIndex,
    isOpen,
    refs,
    // listItemsRef,
    // listContentRef,
    update,
    // setActiveIndex,
    setIsOpen,
    getReferenceProps,
    getFloatingProps,
    // getItemProps,
  } = usePopover<TriggerElement>({ placement, middlewareOptions, interactionOptions, transform });

  useDidUpdate(() => {
    if (isOpen) {
      // passed in open event function should fire on every open
      onOpen?.();
    } else {
      // passed in close event function should fire on every close
      onClose?.();
    }
    onToggle?.(isOpen);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (refs.reference.current && refs.floating.current && isOpen) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
    return;
  }, [open, update, refs.reference, refs.floating]);

  const handleInitialYPosition = () => {
    if (initialOffset?.y && refs?.reference?.current) {
      return refs.reference.current?.getBoundingClientRect()?.y + initialOffset?.y;
    }
    return y;
  };

  const handleInitialXPosition = () => {
    if (initialOffset?.x && refs?.reference?.current) {
      return refs.reference.current?.getBoundingClientRect()?.x + initialOffset?.x;
    }
    return x;
  };

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    refs,
    getTriggerProps: ((params) => {
      const { onClick, onKeyDown, ...rest } = params ?? {};

      const internalClickHandler: MouseEventHandler<TriggerElement> = (e) => {
        conditionallyStopPropagation(e);
        onClick?.(e);
      };

      const internalKeyDownHandler: KeyboardEventHandler<TriggerElement> = (e) => {
        conditionallyStopPropagation(e);
        onKeyDown?.(e);
      };

      return getReferenceProps({
        ref: refs.setReference,
        onClick: internalClickHandler,
        onKeyDown: internalKeyDownHandler,
        ...rest,
      });
    }) as PropGetters<HTMLElement, UseDefaultEvent, DialogElement>['getTriggerProps'],
    getDialogProps: ((params) => {
      const { onClick, onKeyDown, ...rest } = params ?? {};

      const internalClickHandler: MouseEventHandler<DialogElement> = (e) => {
        conditionallyStopPropagation(e);
        onClick?.(e);
      };

      const internalKeyDownHandler: KeyboardEventHandler<DialogElement> = (e) => {
        conditionallyStopPropagation(e);
        onKeyDown?.(e);
      };

      return {
        context,
        descriptionId: `${nodeId}-description`,
        initialFocus,
        labelId: `${nodeId}-label`,
        isOpen,
        nodeId,
        ...getFloatingProps({
          ref: refs.setFloating,
          style: {
            margin: 'unset',
            position: strategy,
            top: handleInitialYPosition() ?? 'unset',
            left: handleInitialXPosition() ?? 'unset',
          },
          onClick: internalClickHandler,
          onKeyDown: internalKeyDownHandler,
          ...rest,
        }),
      };
    }) as PropGetters<HTMLDialogElement, UseDefaultEvent, DialogElement>['getDialogProps'],
  };
};
