import { isNumber } from 'lodash-es';
import { createNumberMask } from 'text-mask-addons';

type NumericOperationProps = {
  allowDecimal: boolean;
  max: number;
  min?: number;
  value?: string;
};

type EnsuredNumericProps = Omit<NumericOperationProps, 'value'> & { value: number };

type EnsureNumericFn = (props: EnsuredNumericProps) => number;
type EnsureNumericReturn = (props: NumericOperationProps) => number;

const ensureNumericValue =
  (fn: EnsureNumericFn): EnsureNumericReturn =>
  ({ value = '', ...rest }: NumericOperationProps): number => {
    const castValue = +(value || '').replace(/,/g, '');
    return fn({
      ...rest,
      value: isNaN(castValue) ? 0 : castValue,
    });
  };

const hasDecimal = (n: number): boolean => n % 1 !== 0;

export const incrementValue = ensureNumericValue(({ value, allowDecimal, max, min = 0 }) => {
  let next = value;
  if (allowDecimal && hasDecimal(value)) {
    next = Math.ceil(next);
  } else {
    next += 1;
  }
  if (isNumber(min) && next < min!) return min;
  return +(next <= max ? next : max);
});

export const decrementValue = ensureNumericValue(({ value, allowDecimal, max, min }) => {
  let next = value;
  if (allowDecimal && hasDecimal(value)) {
    next = Math.floor(next);
  } else {
    next -= 1;
  }
  if (next > max) return max;
  return +(!isNumber(min) || next >= min! ? next : min!);
});

// also allows negatives
export const floatCharsOnly = (value: string): string => value.replace(/[^0-9-.]/g, '');

/**
 * Used for inserting 2 zeros after the decimal point
 * @param numberMask this is the mask returned by the createNumberMask function
 * @param value this is the previous value of the field
 * @returns
 */
export const currencyMask = ({
  numberMask,
  value = '',
}: {
  numberMask: ReturnType<typeof createNumberMask>;
  value: string;
}) => {
  return (rawValue: string) => {
    // when removing the dot, remove the trailing 00 to avoid inserting an extra 00 after applying the mask
    const dotRemoved = value.includes('.') && !rawValue.includes('.');
    if (dotRemoved) rawValue = rawValue.replace(/00$/g, '');
    // remove the 0 before the decimal point when typing a number
    const hadZeroValue = !!value.match(/^0?\./g) && !!rawValue.match(/\D+0\.00/g);
    if (hadZeroValue) rawValue = rawValue.replace(/0\./g, '.');
    // insert 0 at the start if the value starts with the decimal point
    rawValue = rawValue.replace(/^\D\./g, '0.');
    const mask = numberMask(rawValue);
    const result = /\.([0-9]{1,2})/.exec(rawValue);

    // In case there is only one decimal digit
    if (result && result[1].length < 2) {
      mask.push('0');
    } else if (!result) {
      mask.push('0');
      mask.push('0');
    }

    return mask;
  };
};
