import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type TableContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  disabledColor: WeaveThemeValues['Color'];
  selectedRowBackgroundColor: WeaveThemeValues['Color'];
  hoverBackgroundColor: WeaveThemeValues['Color'];
  backgroundColor: WeaveThemeValues['Color'];
  cellFontColor: WeaveThemeValues['Color'];
  headerFontColor: WeaveThemeValues['Color'];
  fontWeight: WeaveThemeValues['FontWeight'];
  fontSize: WeaveThemeValues['FontSize'];
  popoverZIndex: WeaveThemeValues['ZIndex'];
  cellBorderColor: WeaveThemeValues['Color'] | null;
  focusColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  stickyBoxShadow: WeaveThemeValues['BoxShadow'];
  leftShadow: WeaveThemeValues['BoxShadow'];
  rightShadow: WeaveThemeValues['BoxShadow'];
};

export type TableCellThemeValues = {
  cellFontColor: WeaveThemeValues['Color'];
  disabledCellFontColor: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: WeaveThemeValues['FontWeight'];
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
};

export type TableHeaderThemeValues = {
  headerFontColor: WeaveThemeValues['Color'];
  activeHeaderFontColor: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: WeaveThemeValues['FontWeight'];
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
  hoverBackgroundColor: WeaveThemeValues['Color'];
};

export type TableMobileRowActionsThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  selectedRowBackgroundColor: WeaveThemeValues['Color'];
  expandedRowBackgroundColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type TableRowCardContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderBottomColor: WeaveThemeValues['Color'];
};

export type TableRowCardThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type SortingCaretThemeValues = {
  activeCaretColor: WeaveThemeValues['Color'];
  inactiveCaretColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type HoverRowActionsThemeValues = {
  floatingShadow: WeaveThemeValues['BoxShadow'];
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  backgroundColor: WeaveThemeValues['Color'];
};

export type ColumnResizerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  hoverColor: WeaveThemeValues['Color'];
  activeColor: WeaveThemeValues['Color'];
};

export type TableFiltersThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export type FilterNotificationBadgeThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  defaultBorderColor: WeaveThemeValues['Color'];
  focusBorderColor: WeaveThemeValues['Color'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export type FilterColumnsThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type ReorderColumnWhileTapThemeValues = {
  boxShadow: WeaveThemeValues['BoxShadow'];
  backgroundColor: WeaveThemeValues['Color'];
};

export const tableContainerTheme: TableContainerThemeValues = {
  spacing: theme.spacing,
  disabledColor: theme.colors.neutral60,
  selectedRowBackgroundColor: theme.colors.neutral5,
  hoverBackgroundColor: theme.colors.neutral5,
  backgroundColor: theme.colors.white,
  cellFontColor: theme.colors.black,
  headerFontColor: theme.colors.neutral40,
  fontWeight: theme.font.weight.regular,
  fontSize: theme.fontSize,
  popoverZIndex: theme.zIndex.popover,
  cellBorderColor: theme.colors.neutral20,
  focusColor: theme.colors.primary50,
  borderRadius: theme.borderRadius.small,
  stickyBoxShadow: theme.shadows.light,
  leftShadow: theme.shadows.innerLeft,
  rightShadow: theme.shadows.innerRight,
};

export const tableContainerThemeOriginal: TableContainerThemeValues = {
  spacing: themeOriginal.spacing,
  disabledColor: themeOriginal.colors.disabled,
  selectedRowBackgroundColor: themeOriginal.colors.gray200,
  hoverBackgroundColor: themeOriginal.colors.gray200,
  backgroundColor: themeOriginal.colors.white,
  cellFontColor: themeOriginal.colors.gray600,
  headerFontColor: themeOriginal.colors.gray500,
  fontWeight: themeOriginal.font.weight.regular,
  fontSize: themeOriginal.fontSize,
  popoverZIndex: themeOriginal.zIndex.popover,
  cellBorderColor: themeOriginal.colors.gray400,
  focusColor: themeOriginal.colors.weaveBlue,
  borderRadius: themeOriginal.borderRadius.small,
  stickyBoxShadow: themeOriginal.shadows.light,
  leftShadow: themeOriginal.shadows.innerLeft,
  rightShadow: themeOriginal.shadows.innerRight,
};

export const tableCellTheme: TableCellThemeValues = {
  cellFontColor: theme.font.colors.default,
  disabledCellFontColor: theme.font.colors.disabled,
  fontSize: theme.fontSize,
  fontWeight: theme.font.weight.regular,
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
};

export const tableCellThemeOriginal: TableCellThemeValues = {
  cellFontColor: themeOriginal.font.colors.default,
  disabledCellFontColor: themeOriginal.font.colors.disabled,
  fontSize: themeOriginal.fontSize,
  fontWeight: themeOriginal.font.weight.regular,
  spacing: themeOriginal.spacing,
  backgroundColor: themeOriginal.colors.white,
};

export const tableHeaderTheme: TableHeaderThemeValues = {
  headerFontColor: theme.colors.neutral40,
  activeHeaderFontColor: theme.colors.black,
  fontSize: theme.fontSize,
  fontWeight: theme.font.weight.regular,
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
  hoverBackgroundColor: theme.colors.neutral5,
};

export const tableHeaderThemeOriginal: TableHeaderThemeValues = {
  headerFontColor: themeOriginal.colors.gray500,
  activeHeaderFontColor: themeOriginal.colors.gray600,
  fontSize: themeOriginal.fontSize,
  fontWeight: themeOriginal.font.weight.regular,
  spacing: themeOriginal.spacing,
  backgroundColor: themeOriginal.colors.white,
  hoverBackgroundColor: themeOriginal.colors.gray100,
};

export const mobileRowActionsTheme: TableMobileRowActionsThemeValues = {
  backgroundColor: theme.colors.neutral5,
  selectedRowBackgroundColor: theme.colors.neutral5,
  expandedRowBackgroundColor: theme.colors.neutral5,
  spacing: theme.spacing,
};

export const mobileRowActionsThemeOriginal: TableMobileRowActionsThemeValues = {
  backgroundColor: themeOriginal.colors.gray100,
  selectedRowBackgroundColor: themeOriginal.colors.gray200,
  expandedRowBackgroundColor: themeOriginal.colors.gray100,
  spacing: themeOriginal.spacing,
};

export const tableRowCardTheme: TableRowCardThemeValues = {
  borderColor: theme.colors.neutral40,
  spacing: theme.spacing,
};

export const tableRowCardThemeOriginal: TableRowCardThemeValues = {
  borderColor: themeOriginal.colors.gray400,
  spacing: theme.spacing,
};

export const tableRowCardContainerTheme: TableRowCardContainerThemeValues = {
  borderBottomColor: theme.colors.neutral20,
  spacing: theme.spacing,
};

export const tableRowCardContainerThemeOriginal: TableRowCardContainerThemeValues = {
  borderBottomColor: themeOriginal.colors.gray400,
  spacing: themeOriginal.spacing,
};

export const sortingCaretTheme: SortingCaretThemeValues = {
  activeCaretColor: theme.colors.neutral60,
  inactiveCaretColor: theme.colors.neutral40,
  spacing: theme.spacing,
};

export const sortingCaretThemeOriginal: SortingCaretThemeValues = {
  activeCaretColor: themeOriginal.colors.weaveBlue,
  inactiveCaretColor: themeOriginal.colors.gray400,
  spacing: themeOriginal.spacing,
};

export const hoverRowActionsTheme: HoverRowActionsThemeValues = {
  floatingShadow: theme.shadows.floating,
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.small,
  backgroundColor: theme.colors.white,
};

export const hoverRowActionsThemeOriginal: HoverRowActionsThemeValues = {
  floatingShadow: themeOriginal.shadows.hover,
  spacing: themeOriginal.spacing,
  borderRadius: themeOriginal.borderRadius.small,
  backgroundColor: themeOriginal.colors.white,
};

export const columnResizerTheme: ColumnResizerThemeValues = {
  spacing: theme.spacing,
  hoverColor: theme.colors.neutral30,
  activeColor: theme.colors.primary50,
};

export const columnResizerThemeOriginal: ColumnResizerThemeValues = {
  spacing: themeOriginal.spacing,
  hoverColor: themeOriginal.colors.gray300,
  activeColor: themeOriginal.colors.weaveBlue,
};

export const tableFilterTheme: TableFiltersThemeValues = {
  borderColor: theme.colors.neutral20,
  zIndex: theme.zIndex.high,
  spacing: theme.spacing,
};

export const tableFilterThemeOriginal: TableFiltersThemeValues = {
  borderColor: themeOriginal.colors.gray200,
  zIndex: themeOriginal.zIndex.high,
  spacing: themeOriginal.spacing,
};

export const filterColumnsTheme: FilterColumnsThemeValues = {
  borderColor: theme.colors.neutral10,
  spacing: theme.spacing,
};

export const filterColumnsThemeOriginal: FilterColumnsThemeValues = {
  borderColor: themeOriginal.colors.gray100,
  spacing: themeOriginal.spacing,
};

export const reorderColumnWhileTapTheme: ReorderColumnWhileTapThemeValues = {
  backgroundColor: theme.colors.neutral10,
  boxShadow: theme.shadows.floating,
};

export const reorderColumnWhileTapThemeOriginal: ReorderColumnWhileTapThemeValues = {
  backgroundColor: themeOriginal.colors.gray100,
  boxShadow: themeOriginal.shadows.hover,
};

export const filterNotificationBadgeThemeOriginal: FilterNotificationBadgeThemeValues = {
  backgroundColor: themeOriginal.colors.weaveBlue,
  spacing: themeOriginal.spacing,
  defaultBorderColor: themeOriginal.colors.white,
  focusBorderColor: themeOriginal.colors.gray100,
  zIndex: themeOriginal.zIndex.high,
};

export const filterNotificationBadgeTheme: FilterNotificationBadgeThemeValues = {
  backgroundColor: theme.colors.primary50,
  spacing: theme.spacing,
  defaultBorderColor: theme.colors.white,
  focusBorderColor: theme.colors.neutral10,
  zIndex: theme.zIndex.high,
};
