import { css } from '@emotion/react';
import { buttonFocusStyle } from '../buttons/common.styles';
import type { IconButtonStyleValues } from './icon-button-theme';
import { iconButtonTheme, originalIconButtonTheme } from './icon-button-theme';
import { IconButtonSize } from './types';
import { InlineLabelPlacement } from '../forms';

const buttonSizes = {
  normal: 40,
  small: 32,
  xsmall: 24,
};

const colors = {
  default: 600,
  light: 500,
};

type IconButtonStyleProps = {
  size: IconButtonSize;
  color: 'default' | 'light';
  showLabelAlways?: boolean;
  labelPlacement?: InlineLabelPlacement;
  destructive?: boolean;
};

const styles = (
  {
    backgroundColor,
    borderRadius,
    disabledSvgFill,
    destructiveSvgFill,
    disabledTextColor,
    focusRingColor,
    fontSize,
    spacing,
    svgFill,
  }: IconButtonStyleValues,
  { size, color, labelPlacement, showLabelAlways, destructive }: IconButtonStyleProps
) => [
  css`
    min-width: ${buttonSizes[size]}px;
    padding: ${spacing(1)};
    height: ${buttonSizes[size]}px;
    width: ${showLabelAlways ? 'auto' : `${buttonSizes[size]}px`};
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: ${borderRadius};
    display: flex;
    flex-direction: ${labelPlacement === 'right' ? 'row' : 'row-reverse'};
    gap: ${spacing(1)};
    align-items: center;
    justify-content: center;
    transition: background-color 250ms ease-in-out;
    font-size: ${size === 'normal' ? fontSize(16) : fontSize(14)};
    color: ${destructive ? destructiveSvgFill : typeof svgFill === 'string' ? svgFill : svgFill[colors[color]]};
    :hover {
      color: ${color === 'light' && svgFill[colors['default']]};
    }
    ${destructive &&
    `
      svg {
        fill: ${destructiveSvgFill};
      }
    `};
    :hover:not(&[aria-disabled='true']),
    :focus {
      background: ${backgroundColor};
      transition: fill 250ms ease-in-out;
      svg {
        fill: ${color === 'light' && svgFill[colors['default']]};
        :focus::before {
          width: calc(100% + ${spacing(1)});
          height: calc(100% + ${spacing(1)});
        }
      }
    }

    &[aria-disabled='true'] {
      cursor: default;
      color: ${disabledTextColor};
      svg {
        fill: ${disabledSvgFill};
      }
    }
  `,
  buttonFocusStyle({
    borderRadius,
    focusRingColor,
  }),
  showLabelAlways &&
    css`
      border-radius: 4px;
      ::before {
        border-radius: 4px;
      }
    `,
];

export const IconButtonStyles = {
  IconButton: (iconButtonStylesProps: IconButtonStyleProps) => styles(iconButtonTheme, iconButtonStylesProps),
};

export const IconButtonStylesOriginal = {
  IconButton: (iconButtonStylesProps: IconButtonStyleProps) => styles(originalIconButtonTheme, iconButtonStylesProps),
};
