import { HTMLAttributes } from 'react';
import { useStyles } from '../../use-styles';

export type SpinnerSize = 'xl' | 'large' | 'medium' | 'small' | 'xs';

export type SpinningLoaderProps = HTMLAttributes<HTMLDivElement> & {
  size?: SpinnerSize;
};

export const SpinningLoader = ({ size = 'large', ...rest }: SpinningLoaderProps) => {
  const spinnerStyle = useStyles('Loaders', 'spinnerStyle', { size });
  return (
    <div css={spinnerStyle} {...rest}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
