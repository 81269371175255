import { WeaveThemeValues } from '../../component-theme/theme-types';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';

export type BreadcrumbPathStyleValues = {
  clickableColor: WeaveThemeValues['Color'];
  currentPageColor: WeaveThemeValues['Color'];
};

export const breadcrumbPathTheme: BreadcrumbPathStyleValues = {
  clickableColor: theme.font.colors.subdued,
  currentPageColor: theme.font.colors.default,
};

export const breadcrumbPathThemeOriginal: BreadcrumbPathStyleValues = {
  clickableColor: originalTheme.colors.gray400,
  currentPageColor: originalTheme.font.colors.light,
};
