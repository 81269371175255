import {
  ElementType,
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CubeIconSmall, XIconSmall, CaretDownIcon, MultiLocationSmall } from '../../icon';
import { NakedButton } from '../../naked';
import { useStyles } from '../../use-styles';
import type { Variants } from './chip.theme';
import { onlyText } from 'react-children-utilities';
import { TagChipStylesProps, ChipStylesProps } from './chip.styles';
import { PolymorphicComponentPropWithRef, PolymorphicComponentPropWithoutRef, PolymorphicRef } from '../../type-utils';
import { Icon } from '@frontend/icons';
import { useTooltip } from '../tooltip';

export type ChipProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  variant?: Variants;
  className?: string;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  size?: ChipStylesProps['size'];
  editable?: boolean;
  onKeyDown?: KeyboardEventHandler;
  onChange?: ChangeEventHandler;
  trackingId?: string;
  /**
   * ***isResponsive*** should be passed when the chip is part of a container that can get smaller than 120px (such as table cells)
   * Ideally we wouldn't need to pass this prop, but there seems to be a weird behavior if `min()` is used inside `max-width`;
   */
  isResponsive?: boolean;
};

type PolymorphicChipProps<T extends ElementType> = PolymorphicComponentPropWithRef<T, ChipProps>;

const commonEditableStyles = {
  width: 'auto',
  minWidth: '1em',
  gridArea: '1/2',
  font: 'inherit',
  padding: 0,
  margin: '0',
  resize: 'none',
  background: 'none',
  appearance: 'none',
  border: 'none',
} as const;

export const ChipOptional = forwardRef(
  <T extends ElementType = 'div'>(
    {
      as,
      children,
      variant = 'primary',
      leftElement,
      rightElement,
      size = 'default',
      trackingId,
      className,
      editable,
      onChange,
      onKeyDown,
      isResponsive,
      ...rest
    }: PolymorphicChipProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'div';
    const chipStyles = useStyles('Chip', { variant, size, isResponsive });

    const [showTooltip, setShowTooltip] = useState(false);
    const [offsetWidth, setOffsetWidth] = useState<number | undefined>(undefined);
    const {
      Tooltip,
      triggerProps: { ref: triggerRef, ...restTriggerProps },
      tooltipProps,
    } = useTooltip({ initialOpen: false, trigger: 'hover' });
    const spanRef = useRef<HTMLSpanElement | null>(null);

    useLayoutEffect(() => {
      if (!spanRef.current) return;
      // need to trigger a rerender to get the width of the element after it has been rendered
      // otherwise the width will be 0 until a rerender is triggered
      setOffsetWidth(spanRef?.current?.offsetWidth);
    }, [spanRef?.current]);

    function chipMeasureRef(node: HTMLSpanElement) {
      if (node === null) return;

      spanRef.current = node;
      if (!offsetWidth) return;
      if (offsetWidth < node.scrollWidth) {
        setShowTooltip(true);
        triggerRef(node);
      }
    }

    const tooltipText = onlyText(children);

    return (
      <Component
        // Ensure that className passed does not override the .chip class
        className={'chip' + (className ? ` ${className}` : '')}
        css={[
          chipStyles,
          editable && {
            width: 'max-content',
          },
        ]}
        {...rest}
        data-trackingid={trackingId}
        ref={ref}
      >
        {leftElement}
        {editable ? (
          <span
            ref={chipMeasureRef}
            css={{
              display: 'inline-grid',
              '::after': {
                content: 'attr(data-value)',
                visibility: 'hidden',
                whiteSpace: 'pre-wrap',
                height: 1,
                ...commonEditableStyles,
              },
            }}
          >
            <input
              autoFocus
              size={1}
              css={{
                ...commonEditableStyles,
                ':focus': {
                  border: 'none',
                  // The outline will be around the chip component instead of the input
                  outline: 'none',
                },
              }}
              className='chip-input'
              {...restTriggerProps}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                if (e.currentTarget.parentElement) {
                  e.currentTarget.parentElement.dataset.value = e.currentTarget.value;
                }
                onChange?.(e);
              }}
            />
          </span>
        ) : (
          <span className='chip-text' {...restTriggerProps} ref={chipMeasureRef}>
            {children}
          </span>
        )}
        {showTooltip && tooltipText && <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>}
        {rightElement}
      </Component>
    );
  }
);

const RemovableChip = ({
  onClick,
  disabled,
  variant,
  ...props
}: ChipProps & { onClick: MouseEventHandler<HTMLButtonElement>; disabled?: boolean }) => {
  return (
    <ChipOptional
      variant={disabled ? 'disabled' : variant ? variant : 'interactive'}
      {...props}
      rightElement={
        <NakedButton disabled={disabled} onClick={onClick} css={{ display: 'flex' }}>
          <Icon name='x-tiny' color={disabled ? 'disabled' : 'primary'} />
        </NakedButton>
      }
    />
  );
};

export const PetChip = forwardRef<HTMLButtonElement, { leftElement: React.ReactNode; name: string }>(
  ({ leftElement, name, ...rest }, ref) => (
    <ChipOptional variant='neutral' leftElement={leftElement} ref={ref} {...rest}>
      {name}
    </ChipOptional>
  )
);

const PersonChip = ({
  onClick,
  variant = 'outline',
  ...props
}: ChipProps & { avatar: ReactNode; onClick?: MouseEventHandler<HTMLButtonElement> }) => {
  return onClick ? (
    <RemovableChip onClick={onClick} {...props} variant='outline' leftElement={props.avatar} />
  ) : (
    <ChipOptional variant={variant} {...props} leftElement={props.avatar} />
  );
};

const LocationChip = (props: ChipProps) => {
  return (
    <ChipOptional {...props} variant={props.variant ?? 'default'} leftElement={<Icon name='location' size={16} />} />
  );
};

const SingleChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='primaryDark' leftElement={<Icon name='location' size={16} />} />;
};

const MultiChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='warningDark' leftElement={<MultiLocationSmall size={16} />} />;
};

const DepartmentChip = (props: ChipProps) => {
  return <ChipOptional {...props} variant='default' leftElement={<CubeIconSmall size={16} />} />;
};

export type TagChipProps<C extends MouseEventHandler<HTMLButtonElement> | undefined> = Omit<ChipProps, 'variant'> & {
  color: TagChipStylesProps['color'];
  onClick?: C;
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>;
  isRemovable?: boolean;
};

const TagChip = forwardRef(
  <
    C extends MouseEventHandler<HTMLButtonElement> | undefined,
    E extends ElementType = C extends undefined ? 'div' : 'button'
  >(
    {
      color,
      onClick,
      isRemovable,
      onRemoveClick,
      as,
      ...props
    }: PolymorphicComponentPropWithoutRef<E, TagChipProps<C>>,
    ref?: PolymorphicRef<E>
  ) => {
    const tagStyles = useStyles('TagChip', { color, hasOnClick: !!onClick });

    const resolvedAs = as || !!onClick ? 'button' : 'div';

    return (
      <ChipOptional
        as={resolvedAs}
        onClick={onClick}
        css={tagStyles}
        rightElement={
          isRemovable && onRemoveClick ? (
            <NakedButton
              onClick={(e) => {
                e.stopPropagation();
                onRemoveClick(e);
              }}
              css={{
                display: 'flex',
              }}
            >
              <XIconSmall size={13} />
            </NakedButton>
          ) : undefined
        }
        ref={ref}
        {...props}
      />
    );
  }
);

type DropdownFilterChipProps = Omit<ChipProps, 'variant' | 'size' | 'rightElement' | 'onClick'> & {
  filterIsActive?: boolean;
  onClick?: MouseEventHandler;
};

const DropdownFilterChip = forwardRef<HTMLButtonElement, DropdownFilterChipProps>(
  ({ filterIsActive = false, ...props }, ref) => {
    const dropdownFilterStyles = useStyles('DropdownFilterChip', { filterIsActive });

    return (
      <ChipOptional
        as='button'
        css={dropdownFilterStyles}
        size='large'
        rightElement={<CaretDownIcon size={10} />}
        {...props}
        ref={ref}
      />
    );
  }
);

const ChipNamespace = Object.assign(ChipOptional, {
  Removable: RemovableChip,
  Location: LocationChip,
  Department: DepartmentChip,
  Person: PersonChip,
  SingleChip: SingleChip,
  MultiChip: MultiChip,
  Tag: TagChip,
  Pet: PetChip,
  DropdownFilter: DropdownFilterChip,
});

export { ChipNamespace as Chip };
