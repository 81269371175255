import {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  CSSProperties,
  ReactElement,
  forwardRef,
  useState,
  useEffect,
  Ref,
} from 'react';
import { WeaveLogoIcon, UserIcon } from '../../icon';
import type { SizeProps } from './avatar.styles';
import { KeyNames } from '../../constants';
import { useImageReady } from '../../hooks';
import { useStyles } from '../../use-styles';
import { getInitials } from '../../helpers';
import { BottomIcon, AvatarDot } from './atoms';
import { Styles } from '../../use-styles/use-styles';
import { useTooltip } from '../tooltip';
import composeRefs from '@seznam/compose-react-refs';

export interface AvatarProps {
  alt?: string;
  disableClick?: boolean;
  id?: string;
  isWeave?: boolean;
  name?: string;
  onClick?: (e?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
  size?: SizeProps;
  src?: string;
  style?: CSSProperties;
  title?: string;
  children?: ReactNode;
  css?: Styles;
}

const AvatarOptional = forwardRef<HTMLElement, AvatarProps>(
  ({ alt, disableClick, id, isWeave, name, onClick, size = 'medium', src, title, children, ...rest }, ref) => {
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom', hoverDelay: 300 });
    const { ref: tooltipTriggerRef, ...restTriggerProps } = triggerProps;
    const [isError, setIsError] = useState(false);
    const isInteractive = !!onClick && !disableClick;
    const avatarContainer = useStyles('Avatar', 'container', { isInteractive, size });
    const avatarContent = useStyles('Avatar', 'content');
    const sizeStyles = useStyles('Avatar', 'size');

    const imgProps = useImageReady();
    let content: ReactElement;

    const normalizedSrc = !isError ? src : '';

    useEffect(() => {
      if (isError) {
        setIsError(false);
      }
    }, [src]);

    if (isWeave) {
      content = <WeaveLogoIcon size={size === 'xs' ? 16 : 24} />;
    } else if (!normalizedSrc && name) {
      content = <figcaption>{getInitials(name)}</figcaption>;
    } else if (!normalizedSrc && !name) {
      content = <UserIcon color='white' size={size === 'xl' ? 24 : 16} />;
    } else {
      content = (
        <img {...imgProps} onError={() => setIsError(true)} src={normalizedSrc} alt={`${name || 'Unnamed'} avatar`} />
      );
    }

    const handleClick = (e: React.MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      if (disableClick) {
        return;
      } else onClick?.(e);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
        handleClick(event);
      }
    };

    return (
      <>
        <figure
          ref={composeRefs(ref, tooltipTriggerRef as Ref<HTMLElement>)}
          aria-label={`${name || 'Unnamed'} avatar`}
          css={[avatarContainer, sizeStyles?.[size]]}
          id='avatar'
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={isInteractive ? 0 : -1}
          {...restTriggerProps}
          {...rest}
        >
          <div css={avatarContent}>{content}</div>
          {children}
        </figure>
        <Tooltip {...tooltipProps}>{title || name || 'Unnamed avatar'}</Tooltip>
      </>
    );
  }
);

AvatarOptional.displayName = 'Avatar';

const AvatarNamespace = Object.assign(AvatarOptional, {
  Dot: AvatarDot,
  BottomIcon: BottomIcon,
});

export { AvatarNamespace as Avatar };
