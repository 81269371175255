import React, { useLayoutEffect, useMemo } from 'react';
import { ThemeProvider as EmotionThemeProvider, Theme } from '@emotion/react';
import { theme, UniversalBaseStyle, type WeaveTheme } from '@frontend/theme';
import { StylesProvider } from '../use-styles';
import { rootStyles } from '../component-theme';
import { ThemeOptionProvider } from '../theme-option-provider';
import { usePrefersReducedMotion } from '../hooks';
import { FloatingTree } from '@floating-ui/react-dom-interactions';

type ThemeProviderProps = {
  children?: React.ReactNode;
  includeEmotionTheme?: boolean;
  skipAnimation?: boolean;
  heightOffset?: number;
};

// Define these in the global scope because theme and root styles
// are consistent instances.

declare module '@emotion/react' {
  export interface Theme extends WeaveTheme {
    heightOffset: 0;
  }
}

export const ThemeProvider = ({
  children,
  includeEmotionTheme = false,
  skipAnimation = false,
  heightOffset,
}: ThemeProviderProps) => {
  useLayoutEffect(() => {
    document.body.setAttribute('data-theme', 'light');
  }, []);

  const prefersReducedMotion = usePrefersReducedMotion();

  const adjustedTheme = useMemo(() => {
    if (heightOffset) {
      return {
        ...theme,
        heightOffset,
      };
    }

    return theme;
  }, [heightOffset]);

  return (
    <ThemeOptionProvider skipAnimation={prefersReducedMotion || skipAnimation}>
      <FloatingTree>
        {includeEmotionTheme ? (
          <StylesProvider styles={rootStyles}>
            <EmotionThemeProvider theme={adjustedTheme as Theme}>
              <UniversalBaseStyle />
              {children}
            </EmotionThemeProvider>
          </StylesProvider>
        ) : (
          <StylesProvider styles={rootStyles}>
            <UniversalBaseStyle />
            {children}
          </StylesProvider>
        )}
      </FloatingTree>
    </ThemeOptionProvider>
  );
};
