import { AuthStorage, CoreACLs, DecodedSessionToken, DecodedToken, PortalUser, SessionToken } from './types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import jwtDecode from 'jwt-decode';
import { Permission, Permission_index } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';

export const LOGIN_ERROR_CAUSE = {
  credentials: 'credentials',
  unknown: 'unknown',
};

export const getUser = () => {
  return localStorageHelper.get(AuthStorage.user) as PortalUser | undefined;
};

export const getWeaveToken = () => {
  return localStorageHelper.get(AuthStorage.weave_token);
};

export const getDecodedWeaveToken = <A extends CoreACLs | Permission_index = CoreACLs>() => {
  const stored = localStorageHelper.get<DecodedToken<A>>(AuthStorage.decoded_weave);
  if (stored && !isTimeExpired(stored.exp)) {
    return stored;
  } else {
    const token = getWeaveToken();

    if (token) {
      const decodedToken = jwtDecode<DecodedToken<A>>(token);
      localStorageHelper.create(AuthStorage.decoded_weave, decodedToken);
      return decodedToken;
    }
  }
  return;
};

export const getSessionToken = () => {
  return localStorageHelper.get(AuthStorage.weave_session_token);
};

export const getDecodedSessionToken = () => {
  const token = getSessionToken();

  if (token) {
    return {
      decoded: jwtDecode<DecodedSessionToken>(token),
      token,
    } as SessionToken;
  }

  return;
};

/**
 * The weave token determines if the user is authorized to make calls to the weave API
 */
export const isWeaveTokenActive = () => {
  const token = getDecodedWeaveToken();
  if (token) {
    return !isTimeExpired(token.exp);
  }
  return false;
};

export const isWeaveTokenBufferActive = (): boolean => {
  const token = getDecodedWeaveToken();
  if (token) {
    return !isTimeExpired(token.expBuffer);
  }
  return false;
};

export const isSessionTokenActive = () => {
  const token = getDecodedSessionToken();
  if (token) {
    return !isTimeExpired(token.decoded.exp);
  }
  return false;
};

// Simple non-secure hash function that can be used to generate a hash from a string
export const hashCode = function (input: string): number {
  let hash = 0;
  if (input.length === 0) return hash;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

type LoginData = Record<
  string,
  {
    lastLocationId: string;
    lastLocationIds: string[];
    recentOrganizationId: string;
  }
>;
const LOGIN_DATA_KEY = 'weave.login-data';

export const getLoginData = (userId: string) => {
  const data = localStorageHelper.get<LoginData>(LOGIN_DATA_KEY);
  if (!data) {
    return undefined;
  } else {
    return { ...data?.['*'], ...data?.[userId] };
  }
};

export const setLoginData = (userId: string, data: Partial<LoginData[keyof LoginData]>) => {
  const prev = localStorageHelper.get<LoginData>(LOGIN_DATA_KEY) ?? {};
  const newData = { ...prev, [userId]: { ...prev?.[userId], ...data } };
  return localStorageHelper.create(LOGIN_DATA_KEY, newData);
};

const getLocationACLs = <A extends CoreACLs | Permission_index = CoreACLs>(locationId: string) =>
  getDecodedWeaveToken<A>()?.ACLS[locationId];
const getWeaveACLs = <A extends CoreACLs | Permission_index = CoreACLs>() => getLocationACLs<A>('weave');
const isWeaveUserInternal = () => getDecodedWeaveToken()?.type === 'weave';
export const isWeaveUser = () => {
  return isWeaveUserInternal();
};
/** @deprecated Use `hasSchemaACL` in combination with the `Permission` enum from `schema-gen-ts` instead. */
export const hasACL = <A extends CoreACLs | Permission_index = CoreACLs>(locationId: string, acl: A | A[]) => {
  const ACLs = Array.isArray(acl) ? acl : [acl];
  const availableACLs = isWeaveUserInternal() ? getWeaveACLs<A>() : getLocationACLs<A>(locationId);
  return ACLs?.every((acl) => availableACLs?.includes(acl));
};

export const hasSchemaACL = (locationId: string, acl: Permission | Permission[]) => {
  const ACLs = Array.isArray(acl) ? acl : [acl];
  const aclIndexes = ACLs.map((acl) => Permission_index[acl]);
  return hasACL<Permission_index>(locationId, aclIndexes);
};

export const checkAggregateACL = (
  locationIds: string[],
  acl: Permission | Permission[],
  aggregationStrategy: 'all' | 'any' = 'all'
) => {
  const locationACLs = locationIds.map((locationId) => ({ locationId, hasACL: hasSchemaACL(locationId, acl) }));
  const locationACLsMap = Object.fromEntries(locationACLs.map((l) => [l.locationId, l.hasACL]));

  return {
    aclValuesByLocationId: locationACLsMap,
    aggregateValue:
      aggregationStrategy === 'all' ? locationACLs.every((l) => l.hasACL) : locationACLs.some((l) => l.hasACL),
    hasMixedValues: locationACLs.some((l) => l.hasACL) && locationACLs.some((l) => !l.hasACL),
  };
};

// TODO: support multiple auth methods and tabs (think embedding multiple views on a page each with their own auth flow happening)
export const setLastVisitedPage = (uri: string) => {
  localStorageHelper.create(AuthStorage.original_uri, uri);
};

export const getLastVisitedPage = (): string => {
  return localStorageHelper.get(AuthStorage.original_uri) || '/';
};

export const clearLastVisitedPage = () => {
  localStorageHelper.delete(AuthStorage.original_uri);
};

export const clearLocalStorageKeys = (keysToClear: Array<string>) => {
  if (keysToClear.length) {
    keysToClear.forEach((key) => {
      localStorageHelper.delete(key);
    });
  }
};

export const decodeWeaveToken = jwtDecode;

/* ~~~~~~~~~~~~~~~~~~~ local storage ~~~~~~~~~~~~~~~~~~~ */
export const localStorageHelper = {
  get: <T = string>(key: string): T | undefined => {
    const data = localStorage.getItem(key);
    if (!data) return undefined;
    try {
      return JSON.parse(data) as T;
    } catch (e) {
      return data as T;
    }
  },
  create: (key: string, value: string | Record<string, any>) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
      return;
    }
    localStorage.setItem(key, value);
  },
  delete: (key: string | string[]) => {
    if (typeof key === 'string') {
      localStorage.removeItem(key);
      return;
    }
    key.forEach((k) => {
      localStorage.removeItem(k);
    });
  },
};

export const isTimeExpired = (exp?: string | number | undefined): boolean => {
  if (!exp) return false;
  if (typeof exp === 'string') exp = parseInt(exp);
  const tokenExpirationTime = exp * 1000;
  return tokenExpirationTime < new Date().getTime();
};
