import React from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';
import { InlineLabel } from '../../layouts';
import { useRadioContext } from '../../providers';
import { Radio } from './radio.component';

type Props = {
  children: React.ReactNode;
  value: string;
  trackingId?: string;
  className?: string;
};

export const RadioOption = React.memo(({ children, trackingId, value, ...rest }: Props) => {
  const { getOptionProps, labelPlacement } = useRadioContext();
  const optProps = getOptionProps(value);
  const { spacing } = useThemeValues();

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          margin-bottom: ${spacing(2)};
        `,
        labelPlacement === 'left' &&
          css`
            flex-direction: row-reverse;

            label {
              width: 100%;
            }
          `,
      ]}
      {...rest}
    >
      <Radio {...optProps} trackingId={trackingId} />
      <InlineLabel id={optProps.id} placement={labelPlacement}>
        {children}
      </InlineLabel>
    </div>
  );
});
