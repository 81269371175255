import { css } from '@emotion/react';
import { RadioCardThemeValues, radioCardTheme, radioCardThemeOriginal } from './radio-card.theme';

const optionStyle = ({
  selectedColor,
  hoverBGColor,
  borderColor,
  spacing,
  borderRadius,
  backgroundColor,
}: RadioCardThemeValues) => css`
  padding: ${spacing(2)};
  gap: ${spacing(1)};
  display: flex;
  flex: 1;
  position: relative;
  border-radius: ${borderRadius};
  border: 1px solid;
  border-color: ${borderColor};
  color: ${selectedColor};
  transition: color 250ms ease-out, border-color 250ms ease-out;
  background-color: ${backgroundColor};

  &[aria-checked='true'] {
    border-color: ${selectedColor};
  }
  :hover {
    background-color: ${hoverBGColor};
  }
  :focus {
    border-color: ${selectedColor};
    outline-color: ${selectedColor};
    outline-offset: 4px;
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const optionContentStyle = ({ spacing }: RadioCardThemeValues) => css`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: ${spacing(0.5)};
  pointer-events: none;
`;

const optionRadioStyle = css`
  width: 20px;
  height: 20px;
  pointer-events: none;
`;

const fieldStyle = ({ spacing }: RadioCardThemeValues) => css`
  display: flex;
  min-height: 52px;
  gap: ${spacing(1)};
`;

export const RadioCardStyles = {
  RadioCardField: fieldStyle(radioCardTheme),
  RadioCard: optionStyle(radioCardTheme),
  RadioCardContent: optionContentStyle(radioCardTheme),
  RadioCardIcon: optionRadioStyle,
};

export const RadioCardStylesOriginal: typeof RadioCardStyles = {
  RadioCardField: fieldStyle(radioCardThemeOriginal),
  RadioCard: optionStyle(radioCardThemeOriginal),
  RadioCardContent: optionContentStyle(radioCardThemeOriginal),
  RadioCardIcon: optionRadioStyle,
};
