import { ReactComponent as EmptyStateGeneric } from './empty-state-generic.svg';
import { ReactComponent as EmptyStatePayments } from './empty-state-payments.svg';
import { ReactComponent as EmptyStateMarketing } from './empty-state-marketing.svg';
import { ReactComponent as EmptyStateFax } from './empty-state-fax.svg';
import { ReactComponent as EmptyStateFaxContacts } from './empty-state-fax-contacts.svg';
import { ReactComponent as EmptyStateUpload } from './empty-state-upload.svg';
import { ReactComponent as EmptyStateUsers } from './empty-state-users.svg';
import { ReactComponent as EmptyStateOnlineScheduling } from './empty-state-online-scheduling.svg';
import { ReactComponent as EmptyStateVoicemail } from './empty-state-voicemail.svg';
import { ReactComponent as EmptyStateTextConnect } from './empty-state-text-connect.svg';
import { ReactComponent as EmptyStateSyncPhone } from './empty-state-sync-your-phone.svg';
import { ReactComponent as EmptyStateSchedule } from './empty-state-schedule.svg';
import { ReactComponent as EmptyStateReviews } from './empty-state-reviews.svg';
import { ReactComponent as EmptyStateRecentCalls } from './empty-state-recent-calls.svg';
import { ReactComponent as EmptyStateQuickFill } from './empty-state-quick-fill.svg';
import { ReactComponent as EmptyStatePMSConnection } from './empty-state-pms-connection.svg';
import { ReactComponent as EmptyStateOverduePatients } from './empty-state-overdue-patients.svg';
import { ReactComponent as EmptyStateMessages } from './empty-state-messages.svg';
import { ReactComponent as EmptyStateFollowUps } from './empty-state-follow-ups.svg';
import { ReactComponent as EmptyStateEmailOptionsCompose } from './empty-state-email-options-compose.svg';
import { ReactComponent as EmptyStateEmailMarketingSchedule } from './empty-state-email-marketing-schedule.svg';
import { ReactComponent as EmptyStateCollections } from './empty-state-collections.svg';
import { ReactComponent as EmptyStateBirthdays } from './empty-state-birthdays.svg';
import { ReactComponent as EmptyStateAppointmentRequests } from './empty-state-appointment-requests.svg';
import { ReactComponent as EmptyStateContactDirectories } from './empty-state-contact-directories.svg';
import { ReactComponent as EmptyStateScheduleRequests } from './empty-state-schedule-requests.svg';
import { ReactComponent as EmptyStateSummary } from './empty-state-summary.svg';

export const emptyStateGraphics = {
  payments: EmptyStatePayments,
  marketing: EmptyStateMarketing,
  fax: EmptyStateFax,
  fax_contacts: EmptyStateFaxContacts,
  upload: EmptyStateUpload,
  users: EmptyStateUsers,
  generic: EmptyStateGeneric,
  sync_your_phone: EmptyStateSyncPhone,
  online_scheduling: EmptyStateOnlineScheduling,
  voicemail: EmptyStateVoicemail,
  text_connect: EmptyStateTextConnect,
  schedule: EmptyStateSchedule,
  reviews: EmptyStateReviews,
  recent_calls: EmptyStateRecentCalls,
  quick_fill: EmptyStateQuickFill,
  pms_connection: EmptyStatePMSConnection,
  overdue_patients: EmptyStateOverduePatients,
  messages: EmptyStateMessages,
  follow_ups: EmptyStateFollowUps,
  email_options_compose: EmptyStateEmailOptionsCompose,
  email_marketing_schedule: EmptyStateEmailMarketingSchedule,
  collections: EmptyStateCollections,
  birthdays: EmptyStateBirthdays,
  appointment_requests: EmptyStateAppointmentRequests,
  contact_directories: EmptyStateContactDirectories,
  schedule_requests: EmptyStateScheduleRequests,
  summary: EmptyStateSummary,
} as const;
