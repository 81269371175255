import { Text } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';

const errorTypes: {
  [key: number]: {
    headingText: string;
    paragraphText: string;
  };
} = {
  500: {
    headingText: 'We are sorry, but there was a system error while processing your login request',
    paragraphText:
      'Our team has been notified, and we are working to resolve the issue as quickly as possible. Please try again later. If the problem persists, please contact Customer Support at (888) 579-5668 for further assistance.',
  },
  401: {
    headingText: 'Login Failure',
    paragraphText: 'Invalid email or password.',
  },
  402: {
    headingText: 'Trying to log into your account?',
    paragraphText:
      'If your email matches a registered account, you will have received instructions on how to regain access. You can also reset your password.',
  },
  // Add more error codes in future
};

interface SignInErrorProps {
  errorCode: number | undefined;
}

export const SignInError = ({ errorCode }: SignInErrorProps) => {
  const { t } = useTranslation('SignIn');

  const { headingText, paragraphText } = errorTypes[errorCode as keyof typeof errorTypes] || {};

  return (
    <div
      css={css`
        border: 1px solid ${theme.colors.critical50};
        background: ${theme.colors.critical10};
        border-radius: ${theme.borderRadius.medium};
        margin: ${theme.spacing(4, 0, 4)};
        display: flex;
        padding: ${theme.spacing(2)};
      `}
    >
      <Text
        css={css`
          color: ${theme.colors.critical50};
          font-weight: ${theme.font.weight.bold};
        `}
      >
        {t(headingText)}
        <span
          css={css`
            font-weight: ${theme.font.weight.regular};
            margin-left: ${theme.spacing(1)};
          `}
        >
          {t(paragraphText)}
        </span>
      </Text>
    </div>
  );
};
