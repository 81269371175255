import { isFunction } from 'lodash-es';
import type { FC, HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useStyles } from '../../use-styles';

type Props = HTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  disabled?: boolean;
  icon?: FC<React.PropsWithChildren<unknown>>;
  trackingId?: string;
  variant?: 'rounded' | 'rounded-small';
};

export const TextButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, icon, trackingId, variant, ...rest }, ref) => {
    const hasIcon = isFunction(icon);
    const IconComponent = icon as FC<React.PropsWithChildren<unknown>>;
    const styles = useStyles('TextButton', { hasIcon, variant });

    return (
      <button css={styles} type='button' {...rest} ref={ref} data-trackingid={trackingId}>
        {!!icon && <IconComponent />}
        {children}
      </button>
    );
  }
);
