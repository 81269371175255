import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type ChipTheme = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  fontSize: ReturnType<WeaveThemeValues['FontSize']>;
  outlineColor: WeaveThemeValues['Color'];
};

export type Variants = keyof typeof variantsTheme;

export const variantsThemeOriginal = {
  primary: css`
    background: ${themeOriginal.colors.hoverBlue};
    border-radius: ${themeOriginal.borderRadius.small};
    color: ${themeOriginal.colors.gray600};
  `,
  critical: css`
    background: ${themeOriginal.colors.strawberry};
    color: ${themeOriginal.colors.gray100};
  `,
  success: css`
    background: ${themeOriginal.colors.success};
    color: ${themeOriginal.colors.gray600};
  `,
  warn: css`
    background: ${themeOriginal.colors.pizazz};
    color: ${themeOriginal.colors.gray600};
  `,
  disabled: css`
    background: ${themeOriginal.colors.gray300};
    color: ${themeOriginal.colors.gray600};
  `,
  interactive: css`
    border: 1px solid ${themeOriginal.colors.weaveBlue};
    color: ${themeOriginal.colors.weaveBlue};
  `,
  neutral: css`
    background: ${themeOriginal.colors.gray100};
    color: ${themeOriginal.colors.gray600};
  `,
  outline: css`
    border: 1px solid ${themeOriginal.colors.gray600};
    color: ${themeOriginal.colors.gray600};
  `,
};

export const variantsTheme = {
  primary: css`
    background: ${theme.colors.primary10};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.primary50};
  `,
  critical: css`
    background: ${theme.colors.critical10};
    color: ${theme.colors.critical50};
  `,
  success: css`
    background: ${theme.colors.success10};
    color: ${theme.colors.success50};
  `,
  warn: css`
    background: ${theme.colors.warning5};
    color: ${theme.colors.warning80};
  `,
  eggplant: css`
    background: ${theme.colors.secondary.eggplant10};
    color: ${theme.colors.secondary.eggplant50};
  `,
  seaweed: css`
    background: ${theme.colors.secondary.seaweed10};
    color: ${theme.colors.secondary.seaweed50};
  `,
  disabled: css`
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral30};
  `,
  interactive: css`
    border: 1px solid ${theme.colors.primary50};
    color: ${theme.colors.primary50};
  `,
  neutral: css`
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral50};
  `,
  outline: css`
    border: 1px solid ${theme.colors.neutral50};
    color: ${theme.colors.neutral50};
  `,
  default: css`
    background: ${theme.colors.neutral5};
    color: ${theme.colors.neutral50};
  `,
  primaryDark: css`
    background: ${theme.colors.primary20};
    color: ${theme.colors.primary80};
  `,
  warningDark: css`
    background: ${theme.colors.warning50};
    color: ${theme.colors.warning80};
  `,
};

export const chipTheme: ChipTheme = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.small,
  fontSize: theme.font.size.medium,
  outlineColor: theme.colors.primary50,
};

export const chipThemeOriginal: ChipTheme = {
  spacing: themeOriginal.spacing,
  borderRadius: themeOriginal.borderRadius.small,
  fontSize: themeOriginal.font.size.medium,
  outlineColor: themeOriginal.colors.weaveBlue,
};

export type TagColor = keyof typeof tagVariantsTheme;

export const tagVariantsThemeOriginal = {
  blue: {
    styles: css`
      background-color: ${themeOriginal.colors.white};
      border: 1px solid ${themeOriginal.colors.pressedBlue};
      color: ${themeOriginal.colors.pressedBlue};
      svg {
        fill: ${themeOriginal.colors.pressedBlue};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.hoverBlue};
      }
    `,
  },
  green: {
    styles: css`
      background-color: ${themeOriginal.colors.white};
      border: 1px solid ${themeOriginal.colors.success};
      color: ${themeOriginal.colors.success};
      svg {
        fill: ${themeOriginal.colors.success};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.gray100};
      }
    `,
  },
  yellow: {
    styles: css`
      background-color: ${themeOriginal.colors.white};
      border: 1px solid ${themeOriginal.colors.pizazz};
      color: ${themeOriginal.colors.pizazz};
      svg {
        fill: ${themeOriginal.colors.pizazz};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.gray100};
      }
    `,
  },
  red: {
    styles: css`
      background-color: ${themeOriginal.colors.white};
      border: 1px solid ${themeOriginal.colors.error};
      color: ${themeOriginal.colors.error};
      svg {
        fill: ${themeOriginal.colors.error};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.errorHovered};
      }
    `,
  },
  purple: {
    styles: css`
      background-color: ${themeOriginal.colors.white};
      border: 1px solid ${themeOriginal.colors.studio};
      color: ${themeOriginal.colors.studio};
      svg {
        fill: ${themeOriginal.colors.studio};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.gray100};
      }
    `,
  },
  gray: {
    styles: css`
      background-color: ${themeOriginal.colors.gray100};
      border: 1px solid ${themeOriginal.colors.gray600};
      color: ${themeOriginal.colors.gray600};
      svg {
        fill: ${themeOriginal.colors.gray600};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${themeOriginal.colors.gray200};
      }
    `,
  },
};

export const tagVariantsTheme = {
  blue: {
    styles: css`
      background-color: ${theme.colors.primary5};
      border: 1px solid ${theme.colors.primary70};
      color: ${theme.colors.primary70};
      svg {
        fill: ${theme.colors.primary70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.primary10};
      }
    `,
  },
  green: {
    styles: css`
      background-color: ${theme.colors.success5};
      border: 1px solid ${theme.colors.success70};
      color: ${theme.colors.success70};
      svg {
        fill: ${theme.colors.success70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.success10};
      }
    `,
  },
  teal: {
    styles: css`
      background-color: ${theme.colors.secondary.seaweed5};
      border: 1px solid ${theme.colors.secondary.seaweed70};
      color: ${theme.colors.secondary.seaweed70};
      svg {
        fill: ${theme.colors.secondary.seaweed70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.secondary.seaweed10};
      }
    `,
  },
  yellow: {
    styles: css`
      background-color: ${theme.colors.warning5};
      border: 1px solid ${theme.colors.warning80};
      color: ${theme.colors.warning80};
      svg {
        fill: ${theme.colors.warning80};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.warning50};
      }
    `,
  },
  red: {
    styles: css`
      background-color: ${theme.colors.critical5};
      border: 1px solid ${theme.colors.critical70};
      color: ${theme.colors.critical70};
      svg {
        fill: ${theme.colors.critical70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.critical10};
      }
    `,
  },
  purple: {
    styles: css`
      background-color: ${theme.colors.secondary.eggplant5};
      border: 1px solid ${theme.colors.secondary.eggplant70};
      color: ${theme.colors.secondary.eggplant70};
      svg {
        fill: ${theme.colors.secondary.eggplant70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.secondary.eggplant10};
      }
    `,
  },
  gray: {
    styles: css`
      background-color: ${theme.colors.neutral10};
      border: 1px solid ${theme.colors.neutral70};
      color: ${theme.colors.neutral70};
      svg {
        fill: ${theme.colors.neutral70};
      }
    `,
    hoverStyles: css`
      :hover {
        background-color: ${theme.colors.neutral20};
      }
    `,
  },
};

export type DropdownFilterChipTheme = {
  active: {
    backgroundColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
  };
  inactive: {
    borderColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
  };
};

export const dropdownFilterTheme: DropdownFilterChipTheme = {
  active: {
    backgroundColor: theme.colors.primary50,
    color: theme.colors.white,
  },
  inactive: {
    borderColor: theme.colors.neutral50,
    color: theme.colors.neutral90,
  },
};

export const dropdownFilterThemeOriginal: DropdownFilterChipTheme = {
  active: {
    backgroundColor: themeOriginal.colors.weaveBlue,
    color: themeOriginal.colors.white,
  },
  inactive: {
    borderColor: themeOriginal.colors.gray300,
    color: themeOriginal.colors.gray600,
  },
};
