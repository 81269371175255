import { css } from '@emotion/react';
import { Icon, type IconName } from '@frontend/icons';
import { useShellTheme } from './store';
import { useShell } from '@frontend/shell-utils';
import { IconButton, usePortal, type IconButtonProps } from '@frontend/design-system';
import { useShellNavigationState } from './hooks';
import { motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';

type TitleBarButtonProps = Omit<IconButtonProps, 'children' | 'label'> & {
  label?: string;
  icon: IconName;
  hasBorder?: boolean;
};

const TitleBarButton = ({ onClick, disabled, label, icon, hasBorder, ...rest }: TitleBarButtonProps) => {
  const { colorObject } = useShellTheme('colorObject');

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      label={label}
      size='small'
      css={css`
        -webkit-app-region: no-drag;
        color: ${colorObject.iconColor};
        transition: 0.3s ease;
        border: ${hasBorder ? `1px solid ${colorObject.iconColor}` : 'none'};

        :hover:not(:disabled) {
          background-color: ${colorObject.hover};
        }
        :focus:not(:disabled) {
          background-color: ${colorObject.hover};
        }
        :disabled {
          color: ${colorObject.iconColor};
          opacity: 0.4;
        }
      `}
      aria-label={label}
      {...rest}
    >
      <Icon name={icon} />
    </IconButton>
  );
};

const WindowControls = () => {
  const shell = useShell();
  const { t } = useTranslation('base');

  const handleMinimizeWindow = () => {
    shell.emit?.('window:control', 'minimize');
  };

  const handleCloseWindow = () => {
    shell.emit?.('window:control', 'close');
  };

  const handleMaximizeWindow = () => {
    shell.emit?.('window:control', 'maximize');
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-right: ${theme.spacing(1.5)};
        margin-left: auto;
        gap: ${theme.spacing(0.5)};
      `}
    >
      <TitleBarButton label={t('Minimize Window')} onClick={handleMinimizeWindow} icon='minus' />
      <TitleBarButton
        label={t('Maximize Window')}
        onClick={handleMaximizeWindow}
        icon='full-screen-windows'
        aria-label={t('Maximize')}
      />
      <TitleBarButton label={t('Close Window')} onClick={handleCloseWindow} icon='x' />
    </div>
  );
};

const NavigationButtons = () => {
  const shell = useShell();
  const { canGoBack, canGoForward } = useShellNavigationState();
  const { t } = useTranslation('base');

  const handleNavigateBack = () => {
    shell.emit?.('window:go-back', undefined);
  };

  const handleNavigateForward = () => {
    shell.emit?.('window:go-forward', undefined);
  };

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          margin-left: ${shell.isMac ? '100px' : '20px'};
          gap: ${theme.spacing(0.5)};
          -webkit-app-region: no-drag;
        `,
        !shell.isWindows &&
          css`
            margin-right: auto;
          `,
      ]}
    >
      <TitleBarButton
        onClick={handleNavigateBack}
        hasBorder
        disabled={!canGoBack}
        label={t('Go Back')}
        icon='caret-left'
      />
      <TitleBarButton
        onClick={handleNavigateForward}
        hasBorder
        disabled={!canGoForward}
        label={t('Go Forward')}
        icon='caret-right'
      />
    </div>
  );
};

export const TitleBarContent = () => {
  const { t } = useTranslation('base');

  return (
    <>
      <NavigationButtons />
      <div
        id='weave-shell-title-bar-content'
        css={css`
          flex-basis: 450px;
          margin-left: auto;
          margin-right: ${theme.spacing(2)};
        `}
      ></div>
      <TitleBarButton
        onClick={() => navigator.clipboard.writeText(window.location.href)}
        label={t('Copy Current URL')}
        showLabelOnHover
        icon='link'
        about={t('Copy Current URL')}
        css={css`
          margin-right: ${theme.spacing(3)};
        `}
      />
    </>
  );
};

const WindowsTitleBarContent = () => {
  const { t } = useTranslation('base');

  return (
    <>
      <NavigationButtons />
      <TitleBarButton
        onClick={() => navigator.clipboard.writeText(window.location.href)}
        label={t('Copy Current URL')}
        showLabelOnHover
        icon='link'
        about={t('Copy Current URL')}
        css={css`
          margin-left: ${theme.spacing(2)};
        `}
      />
      <div
        id='weave-shell-title-bar-content'
        css={css`
          flex-basis: 450px;
          margin-left: ${theme.spacing(1)};
        `}
      ></div>

      <WindowControls />
    </>
  );
};

export const ShellTitleBar = () => {
  const { colorObject } = useShellTheme('colorObject');
  const { Portal } = usePortal({
    mountTarget: '#weave-shell-title-bar',
    attributes: {
      style: `
      height: 100%;
      width: 100%;`,
    },
  });
  const { isWindows } = useShell();

  return (
    <Portal>
      <motion.div
        css={css`
          display: flex;
          align-items: center;
          min-height: 55px;
          height: 55px;
          width: 100%;
          margin-bottom: auto;
          transition: background 0.6s ease-in-out;
        `}
        initial={{ background: 'linear-gradient(135.55deg, #C2F4F1 25.85%, #89E1DB 107.81%)' }}
        animate={{ background: colorObject.shellColor }}
      >
        {isWindows ? <WindowsTitleBarContent /> : <TitleBarContent />}
      </motion.div>
    </Portal>
  );
};
