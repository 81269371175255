import { forwardRef } from 'react';
import { useStyles } from '../../../use-styles';
import type { ButtonRef } from '../button-type';
import { SecondaryButtonProps } from './types';

export const SecondaryButton = forwardRef<ButtonRef, SecondaryButtonProps>(
  ({ children, destructive, size = 'small', trackingId, type = 'button', ...rest }, ref) => {
    const styles = useStyles('SecondaryButton', { destructive, size });

    return (
      <button css={styles} type={type} {...rest} ref={ref} data-trackingid={trackingId}>
        {children}
      </button>
    );
  }
);
