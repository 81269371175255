import { css } from '@emotion/react';
import {
  BackIcon,
  EmailField,
  EmailIcon,
  Heading,
  MessageIcon,
  NakedButton,
  NumberField,
  PhoneField,
  PrimaryButton,
  Text,
  TextLink,
  useFormField,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useState } from 'react';

type Props = {
  setMfaEnabled: (value: boolean) => void;
};

const styles = {
  mfaButton: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(4)};
    width: 100%;
    margin-top: ${theme.spacing(4)};
    &:hover {
      background-color: ${theme.colors.neutral10};
    }
  `,
  backButton: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary50};
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.large};
    gap: ${theme.spacing(1)};
  `,
};

export const MFAForm = ({ setMfaEnabled }: Props) => {
  const { t } = useTranslation('SignIn');
  const [codeType, setCodeType] = useState<'phone' | 'email' | undefined>(undefined);
  const [step, setStep] = useState(1);

  const phoneNumberField = useFormField({
    type: 'phone',
    required: true,
  });
  const emailField = useFormField({
    type: 'email',
    required: true,
  });

  const numberField = useFormField({
    type: 'number',
    required: true,
    min: 3,
    allowDecimal: true,
    allowNegative: false,
    includeThousandsSeparator: false,
    validator: ({ value }: { value: string }) => {
      return value.length === 6 ? '' : t('PIN must be 6 digits');
    },
  });

  return (
    <>
      {step === 1 && (
        <section>
          <Heading level={2}>{t('Enable Multi Factor Authenctication')}</Heading>
          <section
            css={css`
              margin-top: ${theme.spacing(4)};
            `}
          >
            <Text>
              {t(
                'Increase your account’s security by requiring that a code be emailed or texted, then entered when you log in.'
              )}
            </Text>
            <NakedButton
              css={styles.mfaButton}
              onClick={() => {
                setCodeType('email');
                setStep(2);
              }}
            >
              <EmailIcon
                css={css`
                  text-align: center;
                `}
              />
              {t('Send Code via Email')}
            </NakedButton>
            <NakedButton
              css={styles.mfaButton}
              onClick={() => {
                setCodeType('phone');
                setStep(2);
              }}
            >
              <MessageIcon />
              {t('Send Code via Text')}
            </NakedButton>
          </section>
          <TextLink
            weight='bold'
            css={css`
              display: flex;
              justify-content: center;
              margin-top: ${theme.spacing(4)};
              cursor: pointer;
              text-decoration: none;
              width: 100%;
            `}
            onClick={() => {
              setMfaEnabled(false);
            }}
          >
            {t('Skip, and Enable Later')}
          </TextLink>
        </section>
      )}
      {step === 2 && (
        <>
          <Heading>{codeType === 'phone' ? t('Add a Phone Number') : t('Add an Email Address')}</Heading>
          <section
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            <Text
              css={css`
                margin-top: ${theme.spacing(1)};
              `}
            >
              {codeType === 'phone'
                ? t('Enter your phone number to receive a multi-factor authentication code..')
                : t('Enter your email address to receive a multi-factor authentication code.')}
            </Text>
            {codeType === 'email' ? (
              <EmailField
                css={css`
                  margin: ${theme.spacing(3, 0, 5)};
                `}
                name='username'
                {...emailField}
                label={t('Enter an Email Address')}
              />
            ) : (
              <PhoneField
                css={css`
                  margin: ${theme.spacing(3, 0, 5)};
                `}
                label={t('Enter a Phone Number')}
                name=''
                {...phoneNumberField}
              />
            )}
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <NakedButton
                onClick={() => {
                  setCodeType(undefined);
                  setStep(1);
                }}
                css={styles.backButton}
              >
                <BackIcon />
                {t('Back')}
              </NakedButton>
              <PrimaryButton
                size='large'
                css={{ width: 'fit-content' }}
                onClick={() => setStep(3)}
                disabled={codeType === 'email' ? !!emailField.error : !!phoneNumberField.error}
              >
                {t('Continue')}
              </PrimaryButton>
            </div>
          </section>
        </>
      )}
      {step === 3 && (
        <>
          <Heading>{codeType === 'phone' ? t('Verify Phone Number') : t('Verify Email Address')}</Heading>
          <section
            css={css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            <Text
              css={css`
                margin: ${theme.spacing(2, 0, 3)};
              `}
            >
              {codeType === 'phone'
                ? t('Enter a code sent to {{phoneNumber}} to complete setup.', {
                    phoneNumber: phoneNumberField.value,
                  })
                : t('Enter a code sent to {{email}} to complete setup.', { email: emailField.value })}
            </Text>
            <NumberField {...numberField} name={'MFA code'} label={'Enter Code'} />
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: ${theme.spacing(4, 0, 0)};
              `}
            >
              <NakedButton
                onClick={() => {
                  setStep(2);
                }}
                css={styles.backButton}
              >
                <BackIcon />
                {t('Back')}
              </NakedButton>
              <PrimaryButton
                size='large'
                css={{ width: 'fit-content' }}
                onClick={() => setMfaEnabled(false)}
                disabled={!!numberField.error}
              >
                {t('Verify Code')}
              </PrimaryButton>
            </div>
          </section>
        </>
      )}
    </>
  );
};
