import { useState, useRef, useEffect, useLayoutEffect, MouseEventHandler, KeyboardEventHandler } from 'react';
import { Placement, autoUpdate, UseFloatingReturn, ExtendedRefs, useFloating } from '@floating-ui/react';
import {
  InteractionOptions,
  ListRefs,
  MiddlewareOptions,
  OverrideEvent,
  conditionallyStopPropagation,
  usePopover,
} from '../use-popover';

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

type AdditionalMenuProps<T extends HTMLElement> = {
  context: ReturnType<typeof useFloating<T>>['context'];
  nodeId: string;
  isOpen: boolean;
};

type AdditionalTriggerProps = {
  allowPropagation?: boolean;
};

type PropGetters<
  TriggerElement extends HTMLElement,
  UseDefaultEvent extends boolean = false,
  MenuElement extends HTMLElement = HTMLMenuElement,
  ItemElement extends HTMLElement = HTMLButtonElement
> = {
  getTriggerProps: <TriggerProps extends OverrideEvent<TriggerElement, UseDefaultEvent>>(
    userProps?: TriggerProps & AdditionalTriggerProps
  ) => TriggerProps & { ref: ExtendedRefs<TriggerElement>['setReference'] };
  getMenuProps: <MenuProps extends OverrideEvent<MenuElement, UseDefaultEvent>>(
    userProps?: MenuProps
  ) => MenuProps & AdditionalMenuProps<MenuElement>;
  /**
   * All handlers should be passed into this prop getter function, instead of being passed directly to the PopoverMenuItem component.
   * @param userProps
   * @param userProps.disableCloseOnSelect - if true, the menu will not close when the item is selected
   */
  getItemProps: <ItemProps extends OverrideEvent<ItemElement, UseDefaultEvent>>(
    userProps?: ItemProps & { index: number; disableCloseOnSelect?: boolean }
  ) => ItemProps & { index: number };
};

export type { PropGetters as PropGettersMenu };

/**
 * `UseDefaultEvent` is a boolean that determines whether the to use the default event type for the onChange handler or the custom FieldChangeEvent (used throughout the design system)
 */
export type UsePopoverMenuResponse<
  TriggerElement extends HTMLElement,
  UseDefaultEvent extends boolean = false,
  MenuElement extends HTMLElement = HTMLMenuElement,
  ItemElement extends HTMLElement = HTMLButtonElement
> = Pick<UseFloatingReturn<TriggerElement>, 'refs'> & {
  activeIndex: number | null;
  setActiveIndex: (val: number | null) => void;
  isOpen: boolean;
  close: () => void;
  open: () => void;
} & Omit<PropGetters<TriggerElement, UseDefaultEvent, MenuElement, ItemElement>, 'getDialogProps'> &
  ListRefs;

export const usePopoverMenu = <
  TriggerElement extends HTMLElement,
  UseDefaultEvent extends boolean = false,
  MenuElement extends HTMLElement = HTMLMenuElement,
  ItemElement extends HTMLElement = HTMLButtonElement
>({
  placement = 'right-start',
  middlewareOptions = {},
  interactionOptions = {},
}: {
  placement?: Placement;
  middlewareOptions?: Partial<MiddlewareOptions>;
  interactionOptions?: Partial<InteractionOptions<TriggerElement>>;
} = {}): UsePopoverMenuResponse<TriggerElement, UseDefaultEvent, MenuElement, ItemElement> => {
  const defaultInteractionOptions: Partial<InteractionOptions<TriggerElement>> = {
    typeahead: {
      enabled: true,
    },
  };
  const {
    x,
    y,
    strategy,
    nodeId,
    context,
    activeIndex,
    isOpen,
    open,
    close,
    refs,
    listItemsRef,
    listContentRef,
    update,
    setActiveIndex,
    setIsOpen,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = usePopover<TriggerElement>({
    placement,
    middlewareOptions,
    interactionOptions: {
      ...interactionOptions,
      typeahead: {
        ...defaultInteractionOptions.typeahead,
        ...interactionOptions.typeahead,
      },
    },
  });

  const [controlledScrolling, setControlledScrolling] = useState(false);
  const prevActiveIndex = usePrevious<number | null>(activeIndex);

  /**
   * Scroll the active or selected item into view when in `controlledScrolling`
   * mode (i.e. arrow key nav).
   */
  useLayoutEffect(() => {
    /**
     * adds a little space at the top/bottom during keyboard scroll
     * so that the menu items don't get pushed too close to the boundary of the flyout.
     */
    const CONTAINER_OFFSET_SPACE = 8;
    const floating = refs.floating.current;

    if (isOpen && controlledScrolling && floating) {
      const item = activeIndex != null ? listItemsRef.current[activeIndex] : null;

      if (item && prevActiveIndex != null) {
        const itemHeight = listItemsRef.current[prevActiveIndex]?.offsetHeight ?? 0;

        const floatingHeight = floating.offsetHeight;
        const top = item.offsetTop;
        const bottom = top + itemHeight;

        if (top < floating.scrollTop) {
          // scroll up
          floating.scrollTop -= floating.scrollTop - top + CONTAINER_OFFSET_SPACE;
        } else if (bottom > floatingHeight + floating.scrollTop) {
          // scroll down
          floating.scrollTop += bottom - floatingHeight - floating.scrollTop + CONTAINER_OFFSET_SPACE;
        }
      }
    }
  }, [isOpen, controlledScrolling, prevActiveIndex, activeIndex]);

  // update the size of the flyout if the window changes
  useEffect(() => {
    if (isOpen && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
    return;
  }, [isOpen, update, refs.reference, refs.floating]);

  return {
    activeIndex,
    setActiveIndex,
    isOpen,
    open,
    close,
    refs,
    listItemsRef,
    listContentRef,
    getTriggerProps: ((params) => {
      const { onClick, onKeyDown, ...rest } = params ?? {};

      const internalClickHandler: MouseEventHandler<TriggerElement> = (e) => {
        if (!params?.allowPropagation) {
          conditionallyStopPropagation(e);
        }
        onClick?.(e);
      };

      const internalKeyDownHandler: KeyboardEventHandler<TriggerElement> = (e) => {
        if (!params?.allowPropagation) {
          conditionallyStopPropagation(e);
        }
        onKeyDown?.(e);
      };

      return getReferenceProps({
        ref: refs.setReference,
        onClick: internalClickHandler,
        onKeyDown: internalKeyDownHandler,
        ...rest,
      });
    }) as PropGetters<TriggerElement, UseDefaultEvent, MenuElement, ItemElement>['getTriggerProps'],
    getMenuProps: ((params) => {
      const { onClick, onKeyDown, ...rest } = params ?? {};

      const internalClickHandler: MouseEventHandler<MenuElement> = (e) => {
        conditionallyStopPropagation(e);
        onClick?.(e);
      };

      const internalKeyDownHandler: KeyboardEventHandler<MenuElement> = (e) => {
        conditionallyStopPropagation(e);
        onKeyDown?.(e);
        setControlledScrolling(true);
      };

      return {
        ...getFloatingProps({
          ref: refs.setFloating,
          style: {
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          },
          onPointerEnter() {
            setControlledScrolling(false);
          },
          onPointerMove() {
            setControlledScrolling(false);
          },
          onKeyDown: internalKeyDownHandler,
          onClick: internalClickHandler,
          ...rest,
        }),
        nodeId,
        context,
        isOpen,
      };
    }) as PropGetters<TriggerElement, UseDefaultEvent, MenuElement, ItemElement>['getMenuProps'],
    getItemProps: ((params) => {
      const { onClick, onKeyDown, disableCloseOnSelect, ...rest } = params ?? {};

      const internalClickHandler: MouseEventHandler<ItemElement> = (e) => {
        conditionallyStopPropagation(e);
        onClick?.(e);
        if (!disableCloseOnSelect) {
          setIsOpen(false);
        }
      };

      const internalKeyDownHandler: KeyboardEventHandler<ItemElement> = (e) => {
        conditionallyStopPropagation(e);
        if (e.key === 'Enter' || e.key === ' ') {
          onKeyDown?.(e);
          if (!disableCloseOnSelect) {
            setIsOpen(false);
          }
        }
      };

      return {
        ...getItemProps({
          ref(node) {
            if (params && Number.isInteger(params.index)) {
              listItemsRef.current[params.index] = node;
              listContentRef.current[params.index] = node?.textContent ?? null;
            }
          },
          onClick: internalClickHandler,
          onKeyDown: internalKeyDownHandler,
          ...rest,
        }),
        active: activeIndex === params?.index,
      };
    }) as PropGetters<TriggerElement, UseDefaultEvent, MenuElement, ItemElement>['getItemProps'],
  };
};
