import { css } from '@emotion/react';
import {
  avatarContainerTheme,
  avatarContainerThemeOriginal,
  AvatarContainerThemeValues,
  contentContainerTheme,
  contentContainerThemeOriginal,
  ContentContainerThemeValues,
  sizeTheme,
  sizeThemeOriginal,
  SizeThemeValues,
} from './avatar-theme';

export type SizeProps = keyof ReturnType<typeof sizeStyles>;

type ContainerStylesProps = { size: SizeProps; isInteractive: boolean };

export const sizeStyles = ({ weight, fontSize }: SizeThemeValues) => ({
  xxs: css`
    font-size: ${fontSize.xs};
    font-weight: ${weight.regular};
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    width: 18px;
  `,
  xs: css`
    font-size: ${fontSize.medium};
    font-weight: ${weight.bold};
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px;
  `,
  small: css`
    font-size: ${fontSize.medium};
    font-weight: ${weight.bold};
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
  `,
  medium: css`
    font-size: ${fontSize.large};
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;
  `,
  large: css`
    font-size: ${fontSize.large};
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    width: 48px;
  `,
  xl: css`
    font-size: ${fontSize.large};
    height: 64px;
    min-height: 64px;
    min-width: 64px;
    width: 64px;
  `,
});

export const contentStyles = ({ backgroundColor, color }: ContentContainerThemeValues) =>
  css`
    align-items: center;
    background-color: ${backgroundColor};
    border-radius: 50%;
    color: ${color};
    display: flex;
    height: 100%;
    justify-content: center;
    line-height: 1;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
  `;

const bottomIconPosition = {
  xs: css`
    bottom: -4px;
    right: -4px;
  `,
  small: css`
    bottom: -4px;
    right: 0px;
  `,
  medium: css`
    bottom: -5px;
    right: -5px;
  `,
  large: css`
    bottom: 0px;
    right: 0px;
  `,
  xl: css`
    bottom: 1px;
    right: 5px;
  `,
};

export const dotPosition = {
  xs: css`
    top: -4px;
    right: -4px;
  `,
  small: css`
    top: -5px;
    right: -2px;
  `,
  medium: css`
    top: -4px;
    right: -4px;
  `,
  large: css`
    top: 0px;
    right: -1px;
  `,
  xl: css`
    top: 1px;
    right: 3px;
  `,
};

const containerStyles = (
  { focusRingColor }: AvatarContainerThemeValues,
  { size, isInteractive }: ContainerStylesProps
) => [
  css`
    position: relative;
    /* figure element has default margin styles that need to be removed */
    margin: 0;
    #avatar-bottom-icon > :first-of-type {
      border-radius: 50%;
      position: absolute;
      ${bottomIconPosition[size]}
    }
    #avatar-dot > :first-of-type {
      ${dotPosition[size]}
    }
  `,
  isInteractive &&
    css`
      &:focus {
        outline: 2px solid ${focusRingColor};
        border-radius: 50%;
      }
      &:hover {
        cursor: pointer;
      }
    `,
];

export const AvatarStyles = {
  Avatar: {
    container: (props: ContainerStylesProps) => containerStyles(avatarContainerTheme, props),
    content: contentStyles(contentContainerTheme),
    size: sizeStyles(sizeTheme),
  },
};

export const AvatarStylesOriginal: typeof AvatarStyles = {
  Avatar: {
    container: (props: ContainerStylesProps) => containerStyles(avatarContainerThemeOriginal, props),
    content: contentStyles(contentContainerThemeOriginal),
    size: sizeStyles(sizeThemeOriginal),
  },
};
