import { useEffect, useState } from 'react';
import { useShell } from '@frontend/shell-utils';
import { nanoid } from 'nanoid';

export const useShellNavigationState = () => {
  const shell = useShell();
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  useEffect(() => {
    const updateNavigationState = async () => {
      if (!shell) return;
      const canGoBackValue = await shell.invoke?.('info:can-go-back', undefined);
      const canGoForwardValue = await shell.invoke?.('info:can-go-forward', undefined);
      setCanGoBack(canGoBackValue);
      setCanGoForward(canGoForwardValue);
    };

    const randomId = nanoid(10);
    shell.on?.('info:did-navigate', updateNavigationState, 'navigate-back' + randomId);
    shell.on?.('info:did-navigate-in-page', updateNavigationState, 'navigate-forward' + randomId);
    updateNavigationState();

    return () => {
      shell.off?.('info:did-navigate', updateNavigationState, 'navigate-back' + randomId);
      shell.off?.('info:did-navigate-in-page', updateNavigationState, 'navigate-forward' + randomId);
    };
  }, []);

  return { canGoBack, canGoForward };
};
